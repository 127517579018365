/* eslint-disable */
import { useState, useEffect, useRef, useContext } from 'react'
import api from '../../services/api'
import CartonCart from '../carton/CartonCart'
import Cart from '../Cart/Cart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faX, faFile } from '@fortawesome/free-solid-svg-icons'
import AutoPago from '../AutoPago/AutoPago'
import AuthContext from '../../context/AuthContext'
import axios from 'axios'
import { renderHook } from '@testing-library/react'



function Payment({changeMenu}) {

  const context = useContext(AuthContext)
  const [data, setData] = useState({}) 
  const [cartones, setCartones] = useState([]) 
  const [serialToAdd, setSerialToAdd] = useState();
  const [Orden, setOrden] = useState("asc")
  const [listMode, setListMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  

  const containerRef = useRef(null)
 
  //todo para  configuraciones 
  const [sorteoActual, setSorteoActual] = useState(''); 

  const [textLoading, setTextLoading] = useState('')
  const [modalCart, setModalCart] = useState(false)
  const [modalPagar, setModalPagar] = useState(false)
  const [cart, setCart] = useState([]);
  const [renderCart, setRenderCart] = useState([])
 
  const [popUpTitle, setPopUpTitle] = useState('')
  const [popUpText, setPopUpText] = useState('')  

  const [order , setOrder] = useState(`#${Date.now()}`.replace(/#\d{4}/, "#"));

   

  const handleModalCart = () => {
    setModalCart(true)
  } 
  const GetSorteoActual = async () => {
    setIsLoading(true);
    const response = await api.get(`/read/sorteo_actual`); 
    if (response?.data?.content?.length > 0) {
      const sorteoTempo = response.data.content[0].valor
      setSorteoActual(sorteoTempo)
    }
    setIsLoading(false);
  }
  const GetCartones = async (currentPage) => {
    if( sorteoActual == '') return
    setIsLoading(true);

    try {
      const response = await api.get(`/read/sorteo_cartones_free`); 

      if (response?.data?.content?.length > 0) {
        const TempCartones = response.data.content
        const TempLista = TempCartones.map((item) =>
          {
            return { 
            ...item, 
            numero: item?.serial?.replaceAll(sorteoActual, '') 
          }
        } 
        );
        const filtrados = TempLista.filter((carton)=>
          (
            carton?.id_cliente == context.user.clientId || 
            carton?.id_cliente == 0 || 
            carton?.id_cliente == '0' || 
            carton?.id_cliente == 'null' ||
            carton?.id_cliente == ''
          ) )
          console.log('filtrados:', filtrados)
          console.log('TempLista:', TempLista)

        setCartones(filtrados); 
        setIsLoading(false);
      }  
    } catch (err) {
      console.error('Error al cargar los cartones:', err);
      setIsLoading(false);
    }
    
  }  
  const handleScroll = () => {
    if (!containerRef.current || isLoading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      setPage((prevPage) => prevPage + 1); // Incrementa la página para cargar más
    }
  };  
  const desbloquearCarton = async(item, todos = false) => { 
    //api para desbloquear el carton 
    
    const requestData = new FormData(); 
    let url =  "https://api.bingove.com/v2/desbloquearCarton" 

    if(todos){
      url =  "https://api.bingove.com/v2/limpiarCarrito"  
      requestData.append('clienteId', context.user.clientId ) 
    } else { 
      requestData.append('serial', `${item?.serial}` );
      requestData.append('clienteId', context.user.clientId ) 
    }
    await axios({
      method: "post",
      url: url,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) =>{
        //handle success
        const responseStatus = response?.data?.status;
        const responseMessage = response?.data?.status_message; 
        if(responseStatus != '1'){  
          if(responseMessage){ 
            console.log('responseMessage',responseMessage) 
          } else {
             console.log('Error de API E202404',response) 
          }
        }
         
      })
      .catch((error) =>{ 
        console.log('error al desbbloquear',error )
      });
     
  }
  const checkCarton = async(item) => {
    let responseCheck = ''
    //api para bloquear el carton 
    const requestData = new FormData();
    requestData.append('serial', `${item?.serial}` );
    requestData.append('clienteId', context.user.clientId )
    //ya no es necesario chequear el limite de separacion 
    
    const exist = cart.filter((carton)=>carton.serial == item?.serial)
    let url = "https://api.bingove.com/v2/bloquearCarton"

    if(exist.length > 0){
      url = "https://api.bingove.com/v2/desbloquearCarton"
    }  
    await axios({
      method: "post",
      url: url,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) =>{
        //handle success
        const responseStatus = response?.data?.status;
        const responseMessage = response?.data?.status_message;
        const responseContent = response?.data?.content;
        if(responseStatus == '1'){
          responseCheck = 'true'
          if(responseMessage){ 
            console.log('Respuesta bloquear carton: ', responseMessage)
          }  
        } else {
          if(responseMessage){ 
            responseCheck = responseMessage
            console.log('Respuesta bloquear carton: ', responseMessage)
          } else {
            responseCheck = 'Error de API E200404'
            console.log('response',response)
          }
        }
        
      })
      .catch((error) =>{
        //handle error
        responseCheck = error
        console.log(error, "ops, hubo un error");
      });
    return responseCheck
  }
 

  const removeFromCart = async (item, all = false) => {
    // activamos el loader
    setIsLoading(true)
    //debemos primero verificar que el carton esta libre
    // carton este libre
    console.log('check',item)
    await desbloquearCarton(item, all)
    // desbloqueamos
    //  
    GetCartones()  
    setIsLoading(false) 
    
  }
  const addToCart = async (item) => {
    // activamos el loader
    setIsLoading(true)
    //debemos primero verificar que el carton esta libre
    // carton este libre
    const cartonBloqueado = await checkCarton(item)
    // bloqueamos
    // 
    if(cartonBloqueado == 'true'){ 
      console.log("%c el carton ha sido bloqueado",'color:green')
      GetCartones()  
      setIsLoading(false)

    } else {
      //forzar la actualizacion de cartones
      //mostrar mensajes  cartonBloqueado
      showPopUp(cartonBloqueado)
      console.log("%c ERROR al bloquear el carton",'color:red')
      setIsLoading(false)
      GetCartones() 
    }
  }
 
  const showPopUp = (text = '', title = 'Error') =>{
    setPopUpTitle(title)
    setPopUpText(text)
  }


  const CartonLoader = () => {
    return (
      <div className="flex bg-[#0007] w-[100vw] h-[100vh] top-0 left-0 right-0 justify-center items-center fixed  z-[100]">
        <div className="flex justify-center loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32">
        </div>
        <span className='text-center text-white fixed font-bold'>
          {textLoading != '' ? textLoading : 'CARGANDO'}
        </span>
      </div>
    )
  }

  const PopUpDialog = ({}) => {
    return (
      <div className="flex bg-[#0007] w-[100vw] h-[100vh] top-0 left-0 right-0 justify-center items-center fixed  z-[100]">
        <div className="flex gap-2 flex-col justify-between items-center bg-white rounded h-52 w-52 p-3">
          <span className='text-center text-black-500 font-bold'>
            {popUpTitle != '' &&  popUpTitle }
          </span>
          <span className='text-center text-xs text-black-500'>
            {popUpText }
          </span>
          <button 
              onClick={()=>setPopUpText('')} 
              className='text-center text-sm p-1 px-5 font-bold uppercase bg-gray-300 text-black-500'>
             Cerrar
          </button>
        </div>
      </div>
    )
  }

  useEffect(()=>{ 
    context.CheckCacheLogin()
  },[])
   

  useEffect(() => { 
    GetSorteoActual()
    GetCartones() 
  }, [sorteoActual,data]);

  useEffect(() => { 
    const cartDB = cartones.filter((carton)=>( carton.id_cliente == context.user.clientId ))
    setCart(cartDB)
    setRenderCart(cartDB)  
  }, [cartones, context]); 
  
  useEffect(() => {
    setData(context.user) 
  }, [context])
  
  useEffect(()=>{
    GetCartones()
  },[modalCart])

  useEffect(() => { 
  }, [cart])
  

  return (

    <>
      {popUpText != '' && <PopUpDialog /> }
      <h2 className='text-white text-center text-3xl mt-5'>Cartones Disponibles</h2>

      <div
        ref={containerRef}
        onScroll={handleScroll}
        className=' h-[90vh] w-full'
      >
        {cartones.length === 0 && isLoading ? (
          <CartonLoader />
        ) : (
          <div className='flex flex-wrap gap-3 justify-center z-0 cursor-pointer'>
            <CartonCart
              Bolas={[]} 
              SaveCarton={() => { }}
              Sorteo={sorteoActual}
              serialToAdd={serialToAdd}
              Cartones={cartones}
              Order={Orden}
              FooterName
              HideName
              noDelete
              marcaAgua
              listMode={listMode}
              Ribbon={{}}
              hideLogo={true}
              send={addToCart}
              renderCart={renderCart} 
            />
          </div>
        )}
      </div>
       
       

      {!modalCart && renderCart.length > 0 && !modalPagar &&
        <button
          className='h-10 w-10 bg-purple-500 z-30 fixed top-[85%] right-2 rounded-full mr-4 mb-4'
          onClick={handleModalCart}
        >
          <span className='absolute font-bold bg-red-400 w-6 h-6 -mt-4 -right-3 rounded-full text-white flex items-center justify-center border-solid border-2 border-red-400'>
            {renderCart.length}
          </span>
          <span className='text-white'><FontAwesomeIcon icon={faCartShopping} /></span>
        </button>
      }


      {modalCart &&
        (
          <div className=" backdrop-blur-sm z-10  fixed top-0 left-0 right-0 h-screen transition-transform duration-100 flex justify-end ">
            <div className=" flex w-[19rem] h-full bg-white ">
              <div className='flex flex-col w-full py-14 '>
                <div className="h-[0.8px] bg-[#fff]"></div>
                <div className='flex flex-row h-5 w-full justify-between items-center py-4 px-2 mb-0 bg-[purple]' >
                  <h2 className='text-white'>Carrito</h2>
                  <button
                    onClick={() => setModalCart(false)}>
                    <span><FontAwesomeIcon icon={faX} color='#fff' /></span>
                  </button>
                </div>

                <div className='w-full h-full flex flex-col justify-between overflow-auto'>

                  <Cart
                    renderCart={renderCart}
                    setCart={setCart}
                    sorteoActual={sorteoActual}
                    setModalPagar={setModalPagar}
                    setModalCart={setModalCart}
                    removeItem={removeFromCart}
                    clearItems={removeFromCart}
                  />

                </div>
              </div>
            </div>
          </div>
        )
      }

      {modalPagar &&

        <div className=" backdrop-blur-sm z-10  fixed top-0 left-0 right-0 h-full transition-transform duration-100 flex justify-center items-center ">
          <div className=" flex w-[95%] md:w-[75%] h-[75%] bg-white rounded">
            <div className='flex flex-col w-full'>
              <div className='w-full h-[50px] flex justify-between  pt-3 px-3'>
                <h3>Proceso de Pago</h3>
                <button
                  onClick={() => setModalPagar(false)}> 
                  <FontAwesomeIcon icon={faX} color='#f00' /> 
                </button>
              </div>
                <AutoPago 
                  setModalPagar={setModalPagar} 
                  carrito={cart}  
                  orderNumber={order}
                  sorteoActual={sorteoActual}
                  changeMenu={changeMenu}
                  /> 
            </div>

          </div>
        </div>
      }
    </>
  );
}

export default Payment
