/* eslint-disable */
import { useState, useEffect , useContext } from 'react'
import api from '../../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faX } from '@fortawesome/free-solid-svg-icons'
import CartonInvidual from '../carton/CartonIndividul'
import AuthContext from '../../context/AuthContext'


function GetCarton({ cartonSerial, isOpen, isClose }) {
    const context = useContext(AuthContext)
    const [eyeActive, setEyeActive] = useState(null)
    const [cartones, setCartones] = useState([])
    const [modalCarton, setModalCarton] = useState(isOpen)
    const [CartonesLocal, setCartonesLocal] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [textLoading, setTextLoading] = useState('')
    const [data, setData] = useState([])

    //todo para  configuraciones
    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos, setVendidos] = useState();
    const [TotalRepartir, setTotalRepartir] = useState(0)
    const [sorteoActual, setSorteoActual] = useState();
    const [historial, setHistorial] = useState(false);
    const [PremiosLittle, setPremiosLittle] = useState(0)
    const [PremiosBig, setPremiosBig] = useState(0)
    const [videoHome1, setVideoHome1] = useState({})
    const [videoHome2, setVideoHome2] = useState({})
    const [videoUltimo, setVideoUltimo] = useState({})
    const [publicidad, setPublicidad] = useState(0)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1);
    const [precioCarton, setPrecioCarton] = useState(1);
    const [ventasStatus, setVentasStatus] = useState(false);
    const [promotorStatus, setPromotorStatus] = useState(false);
    const [promotorEmpleo, setPromotorEmpleo] = useState(false);
    const [adminStatus, setAdminStatus] = useState(false);
    const [listMode, setListMode] = useState(false);
    const [infoCart, setInfoCart] = useState([])
    const [serialToAdd, setSerialToAdd] = useState();
    const [Orden, setOrden] = useState("asc")

    console.log(cartones)


    const GetAllConfigs = async () => {
        await api.get('/read/configs')
            .then((response) => {
                if (response?.data?.content.length > 0) {
                    const TempConfigs = response?.data?.content
                    setConfigs(TempConfigs);
                    TempConfigs?.map((item) => {
                        const { id, tipo, valor, extra } = item
                        switch (tipo) {
                            case 'sorteo_actual':
                                setSorteoActual(valor)
                                break;
                            case 'sorteo_youtube':
                                setLink(valor)
                                break;
                            case 'sorteo_fecha':
                                setFecha(valor)
                                break;
                            case 'sorteo_hora':
                                setHora(valor)
                                break;
                            case 'sorteo_dia':
                                setDia(valor)
                                break;
                            case 'ventas_status':
                                setVentasStatus(valor == '1')
                                break;
                            case 'promotores_status':
                                setPromotorStatus(valor == '1')
                                break;
                            case 'administradores_status':
                                setAdminStatus(valor == '1')
                                break;
                            case 'sorteo_rondas':
                                setRondas(valor)
                                break;
                            case 'premio_base_small':
                                setPremiosLittle(parseFloat(valor))
                                break;
                            case 'premio_base_big':
                                setPremiosBig(parseFloat(valor))
                                break;
                            case 'premio_base_total':
                                setTotalRepartir(parseFloat(valor))
                                break;
                            case 'cartones_precio':
                                setPrecioCarton(parseFloat(valor));
                                break;
                            case 'promotores_empleo':
                                setPromotorEmpleo(valor == '1')
                                break;
                            case 'video_home_1':
                                setVideoHome1(item)
                                break;
                            case 'video_home_2':
                                setVideoHome2(item)
                                break;
                            case 'video_ultimo_sorteo':
                                setVideoUltimo(item)
                                break;
                            case 'cartones_vendidos':
                                setVendidos(parseInt(valor))
                                break;
                            case 'precio_publicidad':
                                setPublicidad(parseInt(valor))
                                break;
                            case 'home_historial':
                                setHistorial(valor == '1')
                                break;
                            //cartones_vendidos
                            default: break;
                        }

                    })
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    const GetCartoneIndividual = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`obtenerCarton/${cartonSerial}`);
            if (response?.data?.content?.length > 0) {
                const TempCartones = response.data.content
                const TempLista = TempCartones.map((item) => {
                    return {
                        ...item,
                        numero: item?.serial?.replaceAll(sorteoActual, '')
                    }
                }
                );
                const filtrados = TempLista.filter((carton) =>
                (
                    carton?.id_cliente == context.user.clientId ||
                    carton?.id_cliente == 0 ||
                    carton?.id_cliente == '0' ||
                    carton?.id_cliente == 'null' ||
                    carton?.id_cliente == ''
                ))


                setCartones(filtrados);
                setCartonesLocal(filtrados);
            }
        } catch (err) {
            console.error('Error al cargar los carton:', err);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setData(context.user)
      }, [context])
    
    useEffect(() => {
        GetAllConfigs()
    }, []);

    useEffect(() => {
        GetCartoneIndividual()
    }, [cartonSerial])

    const CartonLoader = () => {
        return (
            <div className="flex bg-[#0007] w-[100vw] h-[100vh] top-0 left-0 right-0 justify-center items-center fixed  z-[100]">
                <div className="flex justify-center loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32">
                </div>
                <span className='text-center text-white fixed font-bold'>
                    {textLoading != '' ? textLoading : 'CARGANDO'}
                </span>
            </div>
        )
    }


    return (

                <div className=" backdrop-blur-sm z-10  fixed top-0 left-0 right-0 h-screen transition-transform duration-100 flex justify-center items-center ">
                    {isLoading ? <CartonLoader /> : (<div className='flex w-[95%] md:w-[75%] h-[75%] flex-col'>
                        <div className='flex flex-col w-[98%] py-2 items-end'>
                            <button
                                onClick={isClose}>
                                <span><FontAwesomeIcon icon={faX} color='#fff' /></span>
                            </button>
                        </div>

                        <div className='w-full h-full flex justify-center items-center'>

                            <CartonInvidual
                                Bolas={[]}
                                Sorteo={sorteoActual}
                                serialToAdd={serialToAdd}
                                CartonesSaved={cartones}
                                Cartones={cartones}
                                Order={Orden}
                                FooterName
                                HideName
                                noDelete
                                marcaAgua
                                listMode={listMode}
                                hideLogo={true}
                                Ribbon={{}}
                            />

                        </div>

                    </div>)}
                </div>

       
    )
}

export default GetCarton
