/* eslint-disable  */
import React, { createContext, useState, useEffect } from 'react'
import { getApi } from '../services/api'

const AuthContext = createContext({})

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [isLogued, setIsLogued] = useState(false)
  const [contexto, setContexto] = useState({
    user,
    login,
    logout,
    CheckCacheLogin,
    isLogued
  })

  async function login(username, password){
    let retorno = false 
    const response = await getApi(`/users/login?username=${username}&password=${password}`, { username, password })
    if (response.data.status == 1) {
      console.log('response internal', response.content)
      setUser(response.data.content[0]) 
      setIsLogued(true)
      window.localStorage.setItem('token', JSON.stringify(response.data.content[0]))
      retorno = true
    } else {
      console.log(response.data.status_message)
      setIsLogued(false)
    }
  
    return retorno
  }

  async function logout() {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('userLogued') 
    setUser({})
    setIsLogued(false)
  }

  function CheckCacheLogin() {
    let retorno = false
    const tokenCache =  window.localStorage.getItem('token')
    
    if(tokenCache){
      setUser(JSON.parse(tokenCache))
      setIsLogued(true)
      retorno = true
    }

    return retorno
  }
   
  useEffect(() => {
    CheckCacheLogin()
  }, [])

  useEffect(() => {
    setContexto({
      user,
      login,
      logout,
      CheckCacheLogin,
      isLogued
    }) 
  }, [ user, isLogued ])
 
  return (
    <AuthContext.Provider value={contexto}>
      {children}
    </AuthContext.Provider>)
}

export { AuthProvider }
export default AuthContext
