/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react'
import { Header } from '../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faHouse, faTableList, faTv, faUser} from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'
import UserHistory from '../../components/dashboard/UserHistory'
import Payment from '../../components/dashboard/Payment'
import ViewSort from '../../components/dashboard/ViewSort'
import UserProfile from '../../components/dashboard/UserProfile'
import HorizontalMenu from '../../components/HorizontalMenu/HorizontalMenu'
import Home from '../home/Home'

function Dashboard() {
  const context = useContext(AuthContext)
  const navigate = useNavigate()
  const [user, setUser] = useState({})
  const [page, setPage] = useState(2)
  const [currentPromotor, setCurrentPromotor] = useState(context?.user?.promotorId)
  
  const handlerChangePage = (newPagge) => {
    setPage(newPagge)
  }

  const PageDisplay = () => {
    switch(page){
      case 0: return <Home changeMenu={handlerChangePage} /> ; break;
      case 1: return <UserHistory changeMenu={handlerChangePage}  /> ; break;
      case 2: return <Payment changeMenu={handlerChangePage}  /> ; break;
      case 3: return <ViewSort changeMenu={handlerChangePage} /> ; break;
      case 4: return <UserProfile changeMenu={handlerChangePage} /> ; break;
    
    }
     
  }
  useEffect(() => {
    setUser(context?.user || {})
    console.log(context)
  }, [])

  useEffect(()=>{
    if(context.isLogued === false){
      navigate('/login')
    }
  },[context,context.isLogued ])

  return (

    <div>

      <div className=' w-full fixed top-0 h-14 z-20 '>
        <header>
          <Header />
        </header>
      </div>

      <main className='flex w-full transition-all'>

        <div className='m-4 items-center mx-auto justify-center'>
          {PageDisplay()}
        </div>

      </main>


      <footer className='bottom-0  overflow-y-hidden z-[10000]'>
        <HorizontalMenu selectedMenu={page} changeMenu={handlerChangePage} />
      </footer>
    </div>
  )
}

export default Dashboard
