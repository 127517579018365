/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"; 
import '../../App.css';
import './header.css';
import {Link, useNavigate } from 'react-router-dom';
import {SiteLogo,SearchBar} from '../../components';
import { faBell,faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import AuthContext from "../../context/AuthContext";
function Header() {  

    const context = useContext(AuthContext)
    const navigate = useNavigate() 
    const [user, setUser] = useState(context.user)
    const [isLogued, setIsLogued] = useState(false) 

    const handlerCheckLogin = async () =>{
        const loggued = context?.CheckCacheLogin()
        if(loggued){
            setIsLogued(true)
        } else {
            setIsLogued(false)
        }
    }

    useEffect(()=>{
        handlerCheckLogin()
    },[])

    const MenuMobile = ()=>{
        return (<>
            <nav className="flex  gap-[10px] no-underline w-7/12 justify-end mr-2">
                 {
                     !isLogued &&
                    <Link key={'link002'} className="main-menu-link no-underline" to="/login" > 
                        <span>Conectate</span>
                        <FontAwesomeIcon icon={faUser} />
                    </Link>
                 }
                {
                    isLogued &&
                    <>
                        {/**
                         * 
                            <SearchBar />   
                        */}
                        <Link key={'link010'} className="main-menu-link no-underline" to="/notificaciones" >                   
                            <FontAwesomeIcon className="icon-notification" icon={faBell} />  
                        </Link>
                        <button 
                            onClick={()=> context.logout()} 
                            className="main-menu-link no-underline" 
                            >                   
                            <span>{ user?.name }</span>
                            <FontAwesomeIcon icon={faUser} /> 
                        </button>
                    </>
                }
            </nav>
            </>
            ); 
    }

  
   
    return (
        <div  className="flex h-14 bg-[purple] z-[10000] right-[0] left-[0] fixed lg:relative w-full mb-1/2">
            <Link key={'link000'} className="main-menu-link link-logo w-1/3 lg:w-2/12" to="/" >
                <SiteLogo />
            </Link>
            <MenuMobile /> 
        </div>
      
    );
  }
  
  export default Header;