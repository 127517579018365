/* eslint-disable */
import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { Header } from '../../components'
import { Link } from 'react-router-dom'


function ResetPassword() {
  const [stage, setStage] = useState(1) // 1: Solicitar codigo, 2: Vlaidar codigo, 3: Cambiar contraseña
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({ 
    username: '',
    phoneNumber: '',
    phoneCode: [ 0, 0, 0, 0, 0, 0 ],
    newPassword: '',
    confirmNewPassword: ''  
  })

  const [step, setStep] = useState(0);
  const code0 = useRef(null);
  const code1 = useRef(null);
  const code2 = useRef(null);
  const code3 = useRef(null);
  const code4 = useRef(null);
  const code5 = useRef(null);
  useEffect(() => {
    console.log("hay un cambio step = " + step);
    switch (step) {
      case 0:
        code0.element?.focus();
        break;
      case 1:
        code1.element?.focus();
        break;
      case 2:
        code2.element?.focus();
        break;
      case 3:
        code3.element?.focus();
        break;
      case 4:
        code4.element?.focus();
        break;
      case 5:
        code5.element?.focus();
        break;
      default:
        code0.element?.focus();
        break;
    }
  }, [step]);

  const handleRequestCode = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch('/api/recover-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'aplication.json'
        },
        body: JSON.stringify({ ...formData.phoneNumber })
      })

      //if (!response.ok) throw new Error('Error al enviar la solicitud')
      
      setMessage('Se ha enviado un código de validación a su número de teléfono')
      setStage(2)
    } catch (error) {
      setMessage('Error al enviar la solicitud. Por favor, intente de nuevo')
    }
  }

  const handleValidationCode = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch('/api/validate-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'aplication/json'
        },
        body: JSON.stringify({ ...formData.phoneNumber, ...formData.phoneCode})
      })

     // if (!response.ok) throw new Error('Código Inválido')
      
      setMessage('Código ingresado satisfactoriamente')
      setStage(3)
    } catch (error) {
      setMessage('Código inválido. Por favor, intente nuevamente')
    }
  }

  const handleChangePassword = async (e) => {
    e.preventDefault()

    if (formData.newPassword !== formData.confirmNewPassword) {
      setMessage('Las contraseñas no coinciden')
      return
    }

    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'aplication/json'
        },
        body: JSON.stringify({ ...formData.phoneNumber, ...formData.phoneCode, ...formData.newPassword })
      })

      if (!response.ok) throw new Error('Error al Cambiar contraseña')
      
      setMessage('Su contraseña ha sido cambiada satisfactoriamente')
      setStage(1) //Resetear el proceso

    } catch (error) {
      setMessage('Error al cambiar contraseña. Por favor, intente de nuevo')
    }
  }

  return (
    <>
      <Header className=''/>
      <main className="flex items-center justify-center min-h-screen bg-inherit">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded">
          <h2 className="text-2xl font-bold text-center">
            Recuperar Contraseña
          </h2>

          <div>
            {stage === 1 && (
              <form className="space-y-4" onSubmit={handleRequestCode}>
                <label className="block font-medium mb-2 text-gray-700">
                  Número de teléfono
                </label>
                <input
                  type="text"
                  placeholder="Escriba su número de teléfono registrado"
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData({...formData, phoneNumber: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
                />
                <button
                  type="submit"
                  className="w-full rounded-lg border-2 p-1 font-[600] bg-[purple] text-white hover:bg-purple-950"
                >
                  Solicitar Código
                </button>
              </form>
            )}

            {stage === 2 && (
              <form className="space-y-4" onSubmit={handleValidationCode}>
                <div>
                  <label className="block text-center font-medium text-gray-700">
                    Ingrese el código de 6 dígitos recibido
                  </label>
                  <div className="flex gap-1 ">
                    <input
                      type="number"
                      ref={code0}
                      className="w-1/6 text-center text-[purple] text-3xl font-bold  py-1 border rounded-md mt-2 focus:outline-none focus:ring focus:ring-[purple] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={formData.phoneCode[0]}
                      required={true}
                      maxLength={1}
                      autoFocus={step === 0}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setStep(1);
                        if (value < 10) {
                          setFormData({
                            ...formData,
                            phoneCode: formData?.phoneCode.map((c, i) =>
                              i === 0 ? value : c
                            ),
                          });
                        }
                      }}
                    />
                    <input
                      type="number"
                      ref={code1}
                      className="w-1/6 text-center text-[purple] text-3xl font-bold  py-1 border rounded-md mt-2 focus:outline-none focus:ring focus:ring-[purple] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={formData?.code?.[1]}
                      required={true}
                      maxLength={1}
                      autoFocus={step === 1}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setFormData({
                          ...formData,
                          phoneCode: formData?.phoneCode.map((c, i) =>
                            i === 1 ? value : c
                          ),
                        });
                        setStep(2);
                      }}
                    />
                    <input
                      type="number"
                      ref={code2}
                      className="w-1/6 text-center text-[purple] text-3xl font-bold  py-1 border rounded-md mt-2 focus:outline-none focus:ring focus:ring-[purple] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={formData?.code?.[2]}
                      required={true}
                      maxLength={1}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setFormData({
                          ...formData,
                          phoneCode: formData?.phoneCode.map((c, i) =>
                            i === 2 ? value : c
                          ),
                        });
                        setStep(3);
                      }}
                    />
                    <input
                      type="number"
                      ref={code3}
                      className="w-1/6 text-center text-[purple] text-3xl font-bold  py-1 border rounded-md mt-2 focus:outline-none focus:ring focus:ring-[purple] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={formData?.code?.[3]}
                      required={true}
                      maxLength={1}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setFormData({
                          ...formData,
                          phoneCode: formData?.phoneCode.map((c, i) =>
                            i === 3 ? value : c
                          ),
                        });
                        setStep(4);
                      }}
                    />
                    <input
                      type="number"
                      ref={code4}
                      className="w-1/6 text-center text-[purple] text-3xl font-bold  py-1 border rounded-md mt-2 focus:outline-none focus:ring focus:ring-[purple] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={formData?.code?.[4]}
                      required={true}
                      maxLength={1}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setFormData({
                          ...formData,
                          phoneCode: formData?.phoneCode.map((c, i) =>
                            i === 4 ? value : c
                          ),
                        });
                        setStep(5);
                      }}
                    />
                    <input
                      type="number"
                      ref={code5}
                      className="w-1/6 text-center text-[purple] text-3xl font-bold  py-1 border rounded-md mt-2 focus:outline-none focus:ring focus:ring-[purple] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={formData?.code?.[5]}
                      required={true}
                      maxLength={1}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setFormData({
                          ...formData,
                          phoneCode: formData?.phoneCode.map((c, i) =>
                            i === 5 ? value : c
                          ),
                        });
                      }}
                    />
                  </div>
                    <button
                      type="submit"
                      className="w-full rounded-lg border-2 p-1 font-[600] bg-[purple] text-white hover:bg-purple-950"
                    >
                      Validar Código
                    </button>
                </div>
              </form>
            )}

            {stage === 3 && (
              <form className="space-y-4" onSubmit={handleChangePassword}>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Nueva Contraseña
                  </label>
                  <input
                    type="password"
                    value={formData.newPassword}
                    placeholder='Escriba su nueva clave alfanumérica'
                    onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                    className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]'
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Confirmar Nueva Contraseña
                  </label>
                  <input
                    type="password"
                    value={formData.confirmNewPassword}
                    placeholder='Confirme su nueva clave alfanumérica'
                    onChange={(e) => setFormData({ ...formData, confirmNewPassword: e.target.value })}
                    className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]'
                  />
                </div>

                <button
                  type="submit"
                  className="w-full rounded-lg border-2 p-1 font-[600] bg-[purple] text-white hover:bg-purple-950"
                >
                  Reestablecer contraseña
                </button>
              </form>
            )}
            
              <p className="flex justify-end mt-2">
                <Link to="/login">
                  <span className="text-red-500">Regresar</span>
                </Link>
              </p>

            {message && <p className='rounded-md px-1 bg-red-500 text-white text-sm'>{message}</p>}
          </div>

        </div>
      </main>
    </>
  );
  }
  
  export default ResetPassword