/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Header } from '../../components'
import PersonalInfo from '../../components/register/PersonalInfo'
import PhoneValidation from '../../components/register/PhoneValidation'
import UserInfo from '../../components/register/UserInfo'
import OptionalInfo from '../../components/register/OptionalInfo'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { generateCode } from '../../helpers/helpers'
import api from '../../services/api'
import { apiAny } from '../../services/api'
import axios from 'axios'

const Register = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(0)
  const [nombreUsuario, setNombreUsuario] = useState('')
  const [telefono, setTelefono] = useState('')
  const [codPais, setCodPais] = useState('+58')
  const [definitivePhone, setDefinitivePhone] = useState('+58')
  const [validationCode, setValidationCode ] = useState(0)
  const [devMode, setDevMode] = useState(true)
  const [generalValues, setGeneralValues] = useState({})
  const [ referido, setReferido ] = useState(null)
  const [ tipoReferido, setTipoReferido ] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [textLoading, setTextLoading] = useState('')
 
  const formTitles = [
    'Información Personal', 
    'Validar Teléfono', 
    'Crear Usuario', 
    'Información Opcional'
  ]

  const initialValues = {
    name: '',
    username: '',
    phoneNumber: '',
    countryCode: codPais,
    phoneCode: Array(6).fill(''),
    password: '',
    confirmPassword: '',
    cedula: '',
    metodoPago: '',
    datosPago: '',
    terminos: false
  }

  const validationSchemas = [
    // Validación para Información Personal
    Yup.object({
      name: Yup.string()
        .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+\s[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+$/, 'Deben ser nombre y apellido')
        .required('1er Nombre y 1er apellido son obligatorios'),

      countryCode:  Yup.string()
        .matches(/^\+\d{1,3}$/, 'Codigo pais no valido Ejemp: +58')
        .required('Codigo de pais es obligatorio'),

      phoneNumber: Yup.string()
        .matches(/^\d+$/, 'Debe ser un número de teléfono')
        .required('El número de teléfono es obligatorio')
        .min(5,'Numero de telefono no valido')
        .max(12,'Numero de telefono no valido')
    }), 
    // Validación para Validar Teléfono
    Yup.object({
      phoneCode: Yup.array()
        .of(
          Yup.string()
            .required('Requerido')
            .matches(/^[0-9]{1}$/, 'Debe ser un dígito')
        )
        .length(6, 'Debe tener extactamente 6 dígitos') 
    }),
    // Validación para Crear Usuario
    Yup.object({
      username: Yup.string()
        .matches(/^\S+$/, 'No debe contener espacios')
        .min(4,'El nombbre de usuario debe contener min 4 caracteres')
        .max(12,'El nombbre de usuario debe contener max 12 caracteres')
        .required('El nombre de usuario es obligatorio')
        ,

      password: Yup.string()
        .matches(/^[a-zA-Z0-9]{8,}$/,'Caracteres especiales no permitidos')
        .min(6, 'La contraseña debe tener al menos 6 caracteres')
        .required('La contraseña es obligatoria'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
        .required('Debe confirmar la contraseña')
    }), 
    // Validación para Información Opcional
    Yup.object({
      cedula: Yup.string()
      .matches(/^[0-9]{6,12}$/, 'Ingrese una numero de documento valido')
      .min(6, 'proporcione detalles de pago validos')
      .required('Debe indicar su nro de cedula o pasaporte'),
      datosPago: Yup.string()
      .matches(/^[a-zA-Z0-9\s,.áéíóúÁÉÍÓÚñÑ-]+$/, 'Caracteres no permitidos')
      .min(6, 'proporcione detalles de pago validos')
      .required('Debe proporcionar los datos de pago'), 
      terminos: 
        Yup.boolean()
        .oneOf([true], 'Debe aceptar los términos y condiciones')
    })
  ]
  const handleDev = () => {
    setDevMode(!devMode)
  }
  const handleSubmit = async (values) => {
    setTextLoading('Comprobando')
    setIsLoading(true);
    const formulario = {
      ...values,
      ref: referido,
      tipoRef: tipoReferido
    }
    setGeneralValues(formulario)

    // aqui es donde completo el registro del cliente con la api
    let responseCheck = ''
    const requestData = new FormData(); 
    requestData.append('cedula',formulario.cedula );
    requestData.append('password',formulario.password );
    requestData.append('name', formulario.name);
    requestData.append('username',formulario.username );
    requestData.append('phoneCode', formulario.phoneCode);
    requestData.append('phoneNumber', formulario.phoneNumber);
    requestData.append('ref', formulario.ref);
    requestData.append('tipoRef', formulario.tipoRef);
    requestData.append('countryCode', formulario.countryCode);
    requestData.append('metodoPago', formulario.metodoPago);
    requestData.append('datosPago', formulario.datosPago);
  
    await axios({
      method: "post",
      url: "https://api.bingove.com/v2/users/create",
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) =>{
        //handle success
        const responseStatus = response?.data?.status;
        const responseMessage = response?.data?.status_message;
        const responseContent = response?.data?.content;
        if(responseStatus == '1'){
          window.localStorage.clear()
          navigate('/login')
        } else {
          if(responseMessage){ 
            responseCheck = responseMessage
            console.log('Respuesta crear usuario: ', responseMessage)
          } else {
            responseCheck = 'Error de API E200747' 
            console.log('response: ',response.data)
          }
        }
        setIsLoading(false);
      })
      .catch((error) =>{
        //handle error
        responseCheck = error
        console.log(error, "ops, hubo un error");
        setIsLoading(false);
      });
    
    console.log('Formulario enviado con éxito:', formulario) 
    
    // Aquí puedes manejar el envío al backend con axios u otra librería
  }
  const enviarCodigo = async () => {
    //definitivePhone
    const temporalCode = generateCode()
    setValidationCode(temporalCode)
    const validationData = new FormData();
    validationData.append('code', temporalCode);
    validationData.append('destination', definitivePhone);
    validationData.append('name', nombreUsuario); 
    
    if(devMode == false){ 
      await apiAny({
        method: "post",
        url: "/sms/token.php",
        data: validationData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    } else {
      alert(`telefono ${definitivePhone}  codigo ${temporalCode}`)
    } 
  }
  const updateDefinitivePhone = (cod = '+58', phone) => {
    let temporalPhone = phone.split('')
    let definitivePhone = phone
    if(cod == '+58'){
      if(temporalPhone[0] == '0'){
        definitivePhone = temporalPhone.filter(( a,i ) => i > 0).join("") 
      }
    }
    const formatedCountryCode = cod.replaceAll('+','00')
    const destino = `${formatedCountryCode}${definitivePhone}`
    setDefinitivePhone(destino) 
  } 
  
  const RegistroLoader = () => {
    return (
      <div className="flex bg-[#0007] w-[100vw] h-[100vh] top-0 left-0 right-0 justify-center items-center fixed  z-[100]">
        <div className="flex justify-center loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32">
        </div>
        <span className='text-center text-white fixed font-bold'>
          {textLoading != '' ? textLoading : 'CARGANDO'}
        </span>
      </div>
    )
  }
  const PageDisplay = (isValid, dirty) => {
    switch (page) {
      case 0:
        return <PersonalInfo 
                  devMode={devMode}  
                  handlerPhone={setTelefono} 
                  handlerCountry={setCodPais} 
                  handlerNombre={setNombreUsuario}
                  onConfirmData={handleConfirmData}
                  isValid={isValid}
                  dirty={dirty}
                  />
      case 1:
        return <PhoneValidation 
                  devMode={devMode}  
                  nextStep={() => setPage((prev) => prev + 1)} 
                  destino={definitivePhone} 
                  code={validationCode} 
                  sendCode={enviarCodigo}  
                  onConfirmData={()=>{}}
                  onCancel={() => setPage((currentPage) => currentPage - 1)}
                  isValid={isValid}
                  dirty={dirty}
                  />
      case 2:
        return <UserInfo 
                  devMode={devMode} 
                  onConfirmData={()=>{}}
                  onCancel={() => setPage((currentPage) => currentPage - 1)}
                  isValid={isValid}
                  dirty={dirty}
                  />
      case 3:
        return <OptionalInfo 
                  devMode={devMode} 
                  onConfirmData={()=>{}}
                  onCancel={() => setPage((currentPage) => currentPage - 1)}
                  isValid={isValid}
                  dirty={dirty}
                  />
      default:
        return null
    }
  }
  const handleConfirmData = (e) => {
    
    if( confirm(`Confirma que el numero de telefono ${codPais} ${telefono} es valido? se enviara un codigo de verificacion.`))
    {
      //realiza las tareas
      enviarCodigo()
    }  
    return
  }

  //obtener el promotor de referencia
  useEffect(()=>{
    const promotorRef = searchParams.get('pref')
    const cllienteRef = searchParams.get('cref')
    if(cllienteRef){
      setReferido(cllienteRef)
      setTipoReferido('cliente')
    } else if(promotorRef){
      setReferido(promotorRef)
      setTipoReferido('promotor')
    } else {
      setReferido(null)
      setTipoReferido(null)
    }
  },[])


  useEffect(()=>{  
    setValidationCode(generateCode())
    updateDefinitivePhone(codPais, telefono)

  },[telefono,codPais])
  return (
    <>
      <Header />
      {isLoading && <RegistroLoader />}
      <main className="flex flex-col items-center justify-center min-h-screen bg-inherit">
        <div className='text-white w-full  gap-3 flex justify-center  mt-10 text-md font-bold uppercase'>
          <label htmlFor="devmode">Modo Desarrollo</label>
          <input 
            id='devmode'
            type="checkbox" 
            checked={devMode} 
            onClick={handleDev} 
          />
        </div>
        <div className="w-full max-w-md p-8 space-y-3 mt-20 bg-white rounded"> 
          <h2 className="text-2xl font-bold text-center">Registro de usuario</h2>
          <div>
            <h3 className="text-lg font-bold text-center">{formTitles[page]}</h3>
          </div>
          
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas[page]}
            onSubmit={(values, actions) => {
              if (page === formTitles.length - 1) {
                
                handleSubmit(values)
              } else {
                setPage((currentPage) => currentPage + 1)
              }
              actions.setTouched({})
            }}
          > 
            {({ isValid, dirty }) => (
              <Form>
                <div>
                  { PageDisplay(isValid, dirty) }
                </div> 
              </Form>
            )}
          </Formik>
          {page === 0 && (
            <p className="text-center mt-1">
              ¿Ya estás registrado?,{' '}
              <Link to="/login">
                <span className="text-red-500">Inicia Sesión</span>
              </Link>
            </p>
          )}
        </div>
      </main>
    </>
  )
}

export default Register
