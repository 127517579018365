/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons'
import './Cart.css'
import { useState } from 'react'
import GetCarton from '../GetCarton/GetCarton'
import { set } from 'react-ga'

const Cart = ({ renderCart, setCart, setModalPagar, setModalCart, removeItem, clearItems }) => {
  const [watchNumbers, setWatchNumbers] = useState([])
  const [cartonSerial, setCartonSerial] =useState([])
  const [isOpen, setIsOpen] =useState(false)


  const handleModals = () => {
    setModalPagar(true)
    setModalCart(false)
  }

  const toggleNumbers = (numero) => {
    setCartonSerial(numero)
    setIsOpen(true)
  };

  const isClose = () => {
    setCartonSerial([])
    setIsOpen(false)
  }


  return (<>
    <div className="w-full h-full flex justify-center items-stretch  bg-white my-2 ">
      <div className='w-full px-0 h-auto'>
        {renderCart.length === 0 ? (
          <div className="flex justify-center items-center h-[90%]">
            <h2>Carrito Vacío</h2>
          </div>
        ) : (
          <div className="h-full w-full flex flex-col justify-between ">
            <div className='w-full h-full overflow-y-auto'>
              {
                renderCart.map((item) => (
                  <div key={item.numero} className=" w-full flex flex-col justify-between ">
                    <div className=" flex h-auto flex-row justify-between items-center px-3">

                      {!watchNumbers[item.numero]
                        ? (


                          <div className="items-center h-[auto]">
                            <span className='bg-green-700 p-2 text-sm font-bold text-white h-full'>CARTÓN {item.numero}</span>
                          </div>
                        )
                        : (
                          <div className='mt-[1px] text-center p-2 text-sm  h-auto bg-gradient-to-r from-violet-950 via-violet-500 to-violet-950'>
                            <span className='font-black'>{item?.numero}</span>
                            <div className='flex flex-wrap max-w-[150px] p-1 gap-2'>
                              {
                                item.numeros
                                  .replaceAll('[', '')
                                  .replaceAll(']', '')
                                  .split(',')
                                  .map((num) => {
                                    return (<>
                                      <span className='font-medium w-[21.5px] bg-orange-100 text-center'>{num}</span>
                                    </>)
                                  })}
                            </div>
                          </div>
                        )
                      }

                      <div className='flex justify-between gap-2 '>
                        <button type='button' onClick={() => toggleNumbers(item.serial)}>

                          <FontAwesomeIcon icon={faEye} />

                        </button>
                        <button onClick={() => removeItem(item)}>
                          <FontAwesomeIcon icon={faTrash} color='red' />
                        </button>
                      </div>
                    </div>
                    <hr />
                  </div>

                ))
              }
            </div>
            <div className='fixed bottom-12 '>
              <div className=' bg-white w-80  flex flex-col border-t-gray-300 border-t-solid border-t-2'>
                <div className='border-b border-t border-b-[#ddd] flex items-center justify-center mb-2'>

                  <h3 className='text-lg m-auto'>Total: <span>${renderCart.length},00</span></h3>
                </div>
                <div className="flex gap-2 justify-center "
                >
                  <button className="w-1/3 mb-2 bg-[#bbb] rounded-md py-1 uppercase font-bold "
                    onClick={() => clearItems(null, true)}
                  >
                    Vaciar
                  </button>
                  <button
                    className="w-1/3 mb-2 bg-purple-500 text-white rounded-md py-2 uppercase font-bold "
                    onClick={() => handleModals()}
                  >Pagar
                  </button>
                </div>
              </div>
            </div>
          </div>

        )}
      </div>
    </div>

    { isOpen &&
      <GetCarton
        cartonSerial={cartonSerial}
        isOpen={isOpen}
        isClose={isClose}
      />
    }

  </>
  );
}

export default Cart;