/* eslint-disable  */
import { Link, useNavigate } from 'react-router-dom'
import { Header } from '../../components'
import React, { useContext, useEffect } from 'react'
import AuthContext from '../../context/AuthContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'

function Login () {
  const context = useContext(AuthContext)
  const navigate = useNavigate()

  // Validación de Yup
  const validationSchema = Yup.object({
    username: Yup.string()
      .required('El nombre de usuario es obligatorio'),
    password: Yup.string()
      .required('La contraseña es obligatoria')
  })

  // Configuración de Formik
  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const isLogin = await context.login(values.username, values.password)
        if (isLogin) {
          navigate('/dashboard')
        } else {
          setFieldError('general', 'Credenciales incorrectas')
        }
      } catch (error) {
        console.error(error)
        setFieldError('general', 'Ocurrió un error al iniciar sesión')
      } finally {
        setSubmitting(false)
      }
    }
  })
  useEffect(()=>{
    if(context.isLogued === true){
      navigate('/dashboard')
    }
  },[context,context.isLogued ])
  return (
    <>
      <Header />
      <main className="flex items-center justify-center min-h-screen bg-inherit">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded">
          <h2 className="text-2xl font-bold text-center">Iniciar Sesión</h2>

          <form onSubmit={formik.handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Nombre de Usuario
              </label>
              <input
                type="text"
                name="username"
                placeholder="Escriba su nombre de Usuario"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
              />
              {formik.touched.username && formik.errors.username
                ? (
                <p className="text-red-500 text-sm">{formik.errors.username}</p>
                  )
                : null}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Contraseña
              </label>
              <input
                type="password"
                name="password"
                placeholder="Escriba su clave alfanumérica"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
              />
              {formik.touched.password && formik.errors.password
                ? (
                <p className="text-red-500 text-sm">{formik.errors.password}</p>
                  )
                : null}
            </div>

            <div>
              <p>
                Si olvidaste tu contraseña,{' '}
                <Link to="/recuperacion">
                  <span className="text-red-500">Click Aquí</span>
                </Link>
              </p>
            </div>

            {formik.errors.general && (
              <p className="text-red-500 text-sm">{formik.errors.general}</p>
            )}

            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-[purple] rounded-md hover:bg-purple-900"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Cargando...' : 'Iniciar Sesión'}
            </button>
            <div>
              <p>
                ¿No tienes una cuenta? {' '}
                <Link to="/register">
                  <span className="text-purple-500">Registrate ahora</span>
                </Link>
              </p>
            </div>
          </form>
        </div>
      </main>
    </>
  )
}

export default Login
