/* eslint-disable */
/* global alert */
/* global localStorage */
import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api";
import { Footer,Youtube,Carton,Tablero } from '../../components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExpand, faInfo, faMinimize } from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import ReactGA from 'react-ga';

const ViewSort = () => {
  const location = useLocation()
  const CARTONSAVED = window.localStorage.getItem('cartonesSaved')

  const [configs, setConfigs] = useState([])
  const [link, setLink] = useState()
  const [vendidos, setVendidos] = useState()
  const [TotalRepartir, setTotalRepartir] = useState(0)
  const [BaseTotalRepartir, setBaseTotalRepartir] = useState(1.00)
  const [sorteoActual, setSorteoActual] = useState()
  const [historial, setHistorial] = useState(false)
  const [PremiosLittle, setPremiosLittle] = useState(0)
  const [PremiosBig, setPremiosBig] = useState(0)
  const [videoHome1, setVideoHome1] = useState({})
  const [videoHome2, setVideoHome2] = useState({})
  const [videoUltimo, setVideoUltimo] = useState({})
  const [publicidad, setPublicidad] = useState(0)
  const [fecha, setFecha] = useState()
  const [dia, setDia] = useState()
  const [hora, setHora] = useState()
  const [rondas, setRondas] = useState(1)
  const [precioCarton, setPrecioCarton] = useState(1)
  const [ventasStatus, setVentasStatus] = useState(false)
  const [promotorStatus, setPromotorStatus] = useState(false)
  const [promotorEmpleo, setPromotorEmpleo] = useState(false)
  const [adminStatus, setAdminStatus] = useState(false)

  const [Cartones, setCartones] = useState([])
  const [serialToAdd, setSerialToAdd] = useState()
  const [premioDiagonal, setPremioDiagonal] = useState(0)
  const [premioEsquinas, setPremioEsquinas] = useState(0)
  const [premioLleno, setPremioLleno] = useState(0)
  const [AppVersion, setAppVersion] = useState(0)
  const [DiaSorteo, setDiaSorteo] = useState(0)
  const [Promotores, setPromotores] = useState(0)
  const [youtubeExpand, setYoutubeExpand] = useState(false)

  const [rondaActual, setRondaActual] = useState(3)
  const [Ganadores_1, setGanadores_1] = useState([])
  const [Ganadores_2, setGanadores_2] = useState([])
  const [Ganadores_3, setGanadores_3] = useState([])
  const [CartonesLocal, setCartonesLocal] = useState([])

  const [porcentajePromotor, setPorcentajePromotor] = useState(0.10)
  const [porcentajeAdmin, setPorcentajeAdmin] = useState(0.05)
  const [bonusPromotor, setBonusPromotor] = useState(0.05)

  const [BolasSorteo, setBolasSorteo] = useState([])
  const [BolasList, setBolasList] = useState([])
  const [BallDelay, setBallDelay] = useState(0)
  const input_add = useRef(null)

  const GetAllConfigs = async () => {
    await api.get('/read/configs')
      .then((response) => {
        if (response?.data?.content.length > 0) {
          const TempConfigs = response?.data?.content
          setConfigs(TempConfigs)
          TempConfigs.map((item) => {
            const { id, tipo, valor, extra } = item
            switch (tipo) {
              case 'sorteo_actual':
                setSorteoActual(valor)
                break
              case 'sorteo_youtube':
                setLink(valor)
                break
              case 'sorteo_fecha':
                setFecha(valor)
                break
              case 'sorteo_hora':
                setHora(valor)
                break
              case 'sorteo_dia':
                setDia(valor)
                break
              case 'ventas_status':
                setVentasStatus(valor == '1')
                break
              case 'promotores_status':
                setPromotorStatus(valor == '1')
                break
              case 'administradores_status':
                setAdminStatus(valor == '1')
                break
              case 'sorteo_rondas':
                setRondas(valor)
                break
              case 'premio_base_small':
                setPremiosLittle(parseFloat(valor))
                break
              case 'premio_base_big':
                setPremiosBig(parseFloat(valor))
                break
              case 'premio_base_total':
                setTotalRepartir(parseFloat(valor))
                break
              case 'cartones_precio':
                setPrecioCarton(parseFloat(valor))
                break
              case 'promotores_empleo':
                setPromotorEmpleo(valor == '1')
                break
              case 'video_home_1':
                setVideoHome1(item)
                break
              case 'video_home_2':
                setVideoHome2(item)
                break
              case 'video_ultimo_sorteo':
                setVideoUltimo(item)
                break
              case 'cartones_vendidos':
                setVendidos(parseInt(valor))
                break
              case 'precio_publicidad':
                setPublicidad(parseInt(valor))
                break
              case 'home_historial':
                setHistorial(valor == '1')
              case 'ball_delay':
                setBallDelay(valor)
                break
                // cartones_vendidos
              default : break
            }
          })
        }
      })
      .catch((err) => {
        console.error('ops! ocorreu um erro' + err)
      })
    let bolitas = []
    await api.get('/read/sorteo_bolitas')
      .then((response) => {
        if (response?.data?.content.length > 0) {
          const TempData = response?.data?.content
          bolitas = TempData[0]?.bolas?.split(',')
        }
      })
    setBolasList(bolitas)
    setBolasSorteo(bolitas)
    updateTotalRepartir()
  }
  const GetCartones = async () => {
    await api.get('/read/sorteo_cartones')
      .then((response) => {
        if (response?.data?.content.length > 0) {
          const TempCartones = response?.data?.content
          setCartones(TempCartones)
          // console.log("TempCartones" ,TempCartones);
        }
      })
      .catch((err) => {
        console.error('ops! ocorreu um erro' + err)
      })
  }

  const GetSorteo = async () => {
    await api.get('/read/sorteo_datos')
      .then((response) => {
        if (response?.data?.content.length > 0) {
          const TempSorteo = response?.data?.content
        }
      })
      .catch((err) => {
        console.error('ops! ocorreu um erro' + err)
      })
  }
  const GetGanadores = async () => {
    await api.get('/read/sorteo_datos')
      .then((response) => {
        if (response?.data?.content.length > 0) {
          const TempSorteo = response?.data?.content
          const GanadoresList1 = TempSorteo[0]?.ganadores_ronda_1
          const GanadoresList2 = TempSorteo[0]?.ganadores_ronda_2
          const GanadoresList3 = TempSorteo[0]?.ganadores_ronda_3
          if (GanadoresList1) { setGanadores_1(GanadoresList1?.split('-')) }
          if (GanadoresList2) { setGanadores_2(GanadoresList2?.split('-')) }
          if (GanadoresList3) { setGanadores_3(GanadoresList3?.split('-')) }
          console.log('TempSorteo', TempSorteo)
        }
      })
      .catch((err) => {
        console.error('ops! ocorreu um erro' + err)
      })
  }

  const updateTotalRepartir = async () => {
    const pagoPromotores = 1 - porcentajePromotor - porcentajeAdmin - bonusPromotor
    const totalVendidosUsd = vendidos * precioCarton
    const totalRecolectado = (totalVendidosUsd * pagoPromotores) - publicidad

    // ssetRondas(1)
    const iniciaRonda = totalRecolectado <= TotalRepartir
    const unaRonda = totalRecolectado <= (BaseTotalRepartir * 2)
    const dosRonda = totalRecolectado > (BaseTotalRepartir * 2) && totalRecolectado <= (BaseTotalRepartir * 3)
    const tresRonda = totalRecolectado > (BaseTotalRepartir * 3)

    if (dosRonda) {
      setRondas(2)
      if (totalRecolectado > TotalRepartir) {
        const premioS = totalRecolectado / (rondas * 8)
        setPremiosLittle(premioS)
        setPremiosBig((premioS) * 2)
        setTotalRepartir(totalRecolectado)
        console.log('dosRonda')
      }
    }
    if (tresRonda) {
      setRondas(3)
      if (totalRecolectado > TotalRepartir) {
        const premioS = totalRecolectado / (rondas * 8)
        setPremiosLittle(premioS)
        setPremiosBig((premioS) * 2)
        setTotalRepartir(totalRecolectado)
        console.log('tresRonda')
      }
    }

    if (iniciaRonda) {
      console.log('iniciaRonda')
    }
    if (unaRonda) {
      if (!iniciaRonda) {
        //    setTotalRepartir(totalRecolectado)
        console.log('unaRonda > iniciaRonda')
      }
      console.log('unaRonda')
    }

    console.log('total Recolectado', totalRecolectado)
    console.log('total a repartir', TotalRepartir)
  }
  const UpdateBolasSorteo = async () => {
    await api.get('/read/sorteo_bolitas')
      .then((response) => {
        if (response?.data?.content.length > 0) {
          const TempData = response?.data?.content
          const BolasParse = TempData[0]?.bolas?.split(',')
          setBolasSorteo(BolasParse)
          console.log('UpdateBolasSorteo', BolasParse)
        }
      })
      .catch((err) => console.error(err))
  }

  const GetBolasSorteo = async () => {
    if (BolasList.length > 0) {
      // console.log('si hay bolitas',BolasList)
      const actuales = BolasList
      let cola = true
      BolasSorteo.map((bolita, index) => {
        if (bolita != actuales[index]) {
          if (cola) {
            actuales.push(bolita)
            cola = false
          }
        }
      })
      if (BolasSorteo.length == 1) {
        setBolasList(BolasSorteo)
      } else {
        setBolasList(actuales)
      }
    }
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [location])

  useEffect(() => {
    if (CARTONSAVED != undefined && CARTONSAVED != null) {
      setCartonesLocal(CARTONSAVED.split(','))
    }
    GetAllConfigs()
    GetCartones()
    const interval = setInterval(() => {
      UpdateBolasSorteo()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      GetBolasSorteo()
    }, BallDelay)
    return clearTimeout(timeout)
  }, [BolasSorteo])

  useEffect(() => {
    GetBolasSorteo()
  }, [BolasSorteo])

  useEffect(() => {
    GetGanadores()
    const interval = setInterval(() => {
      GetGanadores()
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    GetCartones()
  }, [sorteoActual])

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateTotalRepartir()
    }, 60000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    updateTotalRepartir()
  }, [vendidos, PremiosBig, PremiosLittle, rondas])

  const expandbeHandler = () => {
    setYoutubeExpand(!youtubeExpand)
  }
  const Premio = ({ name = 'Esquinas', premio = 0, size = '40', solid }) => {
    let size_premio = size + 'px'
    let size_title = '12px'
    let widthPremio = '140px'
    switch (size) {
      case 'small' : widthPremio = '140px'; size_premio = 20; size_title = 12; break
      case 'medium' : widthPremio = '250px'; size_premio = 40; size_title = 18; break
      case 'large' : widthPremio = '140px'; size_premio = 180; size_title = 90; break
      default : break
    }
    const bg_gradient = solid ? '#7f2e93' : 'linear-gradient(0deg,#7f2e93 0%,#7f2e93 20%,#00000000 100%)'
    return (<>
          <div style={
              {
                background: bg_gradient,
                height: (size_premio + 20) + 'px',
                width: widthPremio,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 90,
                justifyContent: 'center'
              }}>
              <span style={
                  {
                    fontSize: 12,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    lineHeight: (size_title + 10) + 'px'
                  }}>
                  <b style={{ color: 'white', fontSize: size_title + 'px', marginTop: (-size_title - 5) + 'px' }}>{name}</b>
                  <b style={{ color: '#ffcc00', fontSize: size_premio + 'px' }}>$ {premio?.toFixed(2)}</b>
              </span>
          </div>
      </>)
  }
  const TablaLateral = () => {
    return (
          <div className="div-tabla-detalles detalles">
              <img src="./images/logo_bingove_01.png" width={150} height={80} alt="" />
              <br />
              <div className="div-premios" style={{ width: 300, height: 270 }}>
                  <div className="tabla-premios" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                          <div style={{ gap: '5px', display: 'flex', flexDirection: 'row' }}>
                              <Premio size="small" name="Esquinas" premio={PremiosLittle} solid/>
                              <Premio size="small" name="Diagonal" premio={PremiosLittle} solid/>
                          </div>
                          <div style={{ gap: '5px', display: 'flex', flexDirection: 'row' }}>
                              <Premio size="small" name="Diagonal Invetido" premio={PremiosLittle} solid/>
                              <Premio size="small" name="Cruz" premio={PremiosLittle} solid/>
                          </div>
                          <div style={{ gap: '5px', display: 'flex', flexDirection: 'row' }}>
                              <Premio size="small" name="Linea Horizontal" premio={PremiosLittle} solid/>
                              <Premio size="small" name="Linea Vertical" premio={PremiosLittle} solid/>
                          </div>
                      </div>
                      <Premio size="medium" name="Carton Lleno" premio={PremiosBig} solid/>
                      {ventasStatus == '0' && <b style={{ color: 'red' }}>{'VENTAS CERRADAS'}</b>}
                      {ventasStatus == '1' && <b style={{ color: 'green' }}>{'VENTAS ABIERTAS'}</b>}
                  </div>
              </div>
          </div>
    )
  }
  const AcordionHeaderBody = () => {
    return (<div className="accordion-titulo">
          <span className="accordion-titulo-carton">CARTON</span>
          <span className="accordion-titulo-nombre">NOMBRE GANADOR</span>
          <span className="accordion-titulo-premio">PREMIO</span>
      </div>)
  }
  const AcordionHeaderTab = ({ item, title }) => {
    return (<div className="tab-acordion" style={{}}>
          <span>{title}</span>
          <span>{item.length} { item.length == 1 ? 'GANADOR' : 'GANADORES'}</span>
      </div>)
  }
  const AcordionGanador = ({ item, premio, inicial }) => {
    return item?.map((ganador, index) => {
      const contenidoParentesis = ganador.match(/\(([^)]+)\)/)
      const winner = contenidoParentesis ? contenidoParentesis[1] : null
      let match = null
      switch (inicial) {
        case 'L1': match = ganador.match(/L. Horizontal Nro: (\d{4})/); break
        case 'L2': match = ganador.match(/L. Vertical Nro: (\d{4})/); break
        case 'E': match = ganador.match(/ESQUINAS Nro: (\d{4})/); break
        case 'D': match = ganador.match(/DIAGONAL Nro: (\d{4})/); break
        case 'DI': match = ganador.match(/DIAGONAL INV Nro: (\d{4})/); break
        case 'C': match = ganador.match(/CRUZ Nro: (\d{4})/); break
        case 'LL': match = ganador.match(/LLENO {2}Nro: (\d{4})/); break

        default : match = ganador.match(/Linea Nro: (\d{4})/); break
      }

      let numeroCarton = match ? match[1] : null
      if (!numeroCarton) {
        match = ganador.match(/(\d{4})/)
        numeroCarton = match ? match[1] : null
      }
      const numeral = numeroCarton
      const premioActual = premio / item.length
      return (
              <div className="accordion-contenido" key={'ganadores-acordion-' + index}>
                  <span className="accordion-contenido-carton">#{numeral}</span>
                  <span className="accordion-contenido-nombre">{winner}</span>
                  <span className="accordion-contenido-premio">${premioActual.toFixed(2)}</span>
              </div>
      )
    })
  }
  const TablaGanadores = () => {
    const rondas = ['RONDA 1', 'RONDA 2', 'RONDA 3']
    return (<>
          <div className="div-tabla-detalles ganadores">
          <div className="nro-sorteo" style={{ fontSize: 18, gap: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <b>SORTEO {sorteoActual}</b>
          </div>
          <div className="div-ganadores" style={{ gap: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span className="ganador-tittle" style={{ textAlign: 'center' }}>
                  <b>LISTA DE GANADORES</b>
              </span>
              <div className="tabla-ganadores" style={{ minHeight: '290px', display: 'block' }}>
                  <Tabs>
                      <TabList>
                          {rondas.map((ronda, index) => {
                            return (<Tab key={'ganadores-head-' + index}>
                                  <span style={{ fontSize: 14 }} >{ronda}</span>
                              </Tab>)
                          })}
                      </TabList>
                      {rondas.map((ronda, index) => {
                        let Gana = Ganadores_1
                        switch (index) {
                          case 0: Gana = Ganadores_1; break
                          case 1: Gana = Ganadores_2; break
                          case 2: Gana = Ganadores_3; break
                          default: break
                        }
                        const ganadoresLinealH = Gana?.filter((a) => a.includes('L. Horizontal Nro:'))
                        const ganadoresLinealV = Gana?.filter((a) => a.includes('L. Vertical Nro:'))
                        const ganadoresEsquinas = Gana?.filter((a) => a.includes('ESQUINAS Nro:'))
                        const ganadoresDiagonal = Gana?.filter((a) => a.includes('DIAGONAL Nro:'))
                        const ganadoresDiagonalInv = Gana?.filter((a) => a.includes('DIAGONAL INV Nro:'))
                        const ganadoresCruz = Gana?.filter((a) => a.includes('CRUZ Nro:'))
                        const ganadoresLleno = Gana?.filter((a) => a.includes('LLENO  Nro:'))
                        return (<TabPanel key={'ganadores-tabla-' + index}>
                                  <div style={{ maxHeight: '250px', overflowY: 'visible', overflowX: 'hidden' }}>
                                  <Accordion defaultActiveKey={'0'}>
                                      <Accordion.Item eventKey="0" >
                                          <Accordion.Header >
                                             <AcordionHeaderTab title="LINEA HORIZONTAL" item={ganadoresLinealH}/>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                              <AcordionHeaderBody />
                                              <AcordionGanador item={ganadoresLinealH} premio={PremiosLittle} inicial="L1" />
                                          </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="1" >
                                          <Accordion.Header >
                                             <AcordionHeaderTab title="LINEA VERTICAL" item={ganadoresLinealV}/>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                              <AcordionHeaderBody />
                                              <AcordionGanador item={ganadoresLinealV} premio={PremiosLittle} inicial="L2" />
                                          </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="2" >
                                          <Accordion.Header >
                                             <AcordionHeaderTab title="DIAGONAL" item={ganadoresDiagonal}/>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                              <AcordionHeaderBody />
                                              <AcordionGanador item={ganadoresDiagonal} premio={PremiosLittle} inicial="D" />
                                          </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="3" >
                                          <Accordion.Header >
                                             <AcordionHeaderTab title="DIAGONAL INV." item={ganadoresDiagonalInv}/>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                              <AcordionHeaderBody />
                                              <AcordionGanador item={ganadoresDiagonalInv} premio={PremiosLittle} inicial="DI" />
                                          </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="4" >
                                          <Accordion.Header >
                                             <AcordionHeaderTab title="CRUZ GRANDE" item={ganadoresCruz}/>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                              <AcordionHeaderBody />
                                              <AcordionGanador item={ganadoresCruz} premio={PremiosLittle} inicial="C" />
                                          </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="5" >
                                          <Accordion.Header >
                                             <AcordionHeaderTab title="4 ESQUINAS" item={ganadoresEsquinas}/>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                              <AcordionHeaderBody />
                                              <AcordionGanador item={ganadoresEsquinas} premio={PremiosLittle} inicial="E" />
                                          </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="6" >
                                          <Accordion.Header >
                                             <AcordionHeaderTab title="CARTON LLENO" item={ganadoresLleno}/>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                              <AcordionHeaderBody />
                                              <AcordionGanador item={ganadoresLleno} premio={PremiosBig} inicial="LL" />
                                          </Accordion.Body>
                                      </Accordion.Item>
                                  </Accordion>
                                  </div>
                              </TabPanel>
                        )
                      })}

                  </Tabs>
              </div>
          </div>
      </div>
      </>)
  }
  const handleChange = () => {
    setSerialToAdd(input_add?.current?.value)
  }
  const UpdateCartonesLocal = (value = []) => {
    localStorage.setItem('cartonesSaved', value)
    setCartonesLocal(value)
  }
  const CartonAdd = () => {
    return (<>
          <div className="w-full flex gap-x-[20px] bg-[#55bb99]
              p-2 justify-center h-[50px] items-center"

              >
              {!isMobile && <span>AGREGAR CARTON</span>}
              {

              <div id="clickable" data-tooltip-place="top" className="bg-blue-600 w-[30px] h-[30px] rounded-full flex justify-center items-center hover:w-[35px] hover:h-[35px] hover:text-xl">
                  <FontAwesomeIcon icon={faInfo} />
                  <Tooltip anchorSelect="#clickable" className="z-[100000] bg-black flex flex-col justify-start items-center absolute" >
                      <span>Este es el numero</span>
                      <span>que debes ingresar</span>
                      <img src={'/images/carton-explicacion-2.png'} height={200} width={260} alt="sad"/>
                  </Tooltip>
              </div>
              }

              <input
                  className="p-2 text-[black]"
                  ref={input_add}
                  type="number"
                  placeholder="Serial de tu carton"
                  onChange={handleChange}
              />

              <button className="bg-[#7f2e93] p-2 rounded rounded-[5px]"
                  onClick={() => {
                    let validar = false
                    Cartones?.map((e) => {
                      const serial = (e.serial).replace(sorteoActual, '')
                      if (parseInt(serial) == parseInt(serialToAdd)) {
                        UpdateCartonesLocal([...CartonesLocal.filter((a) => parseInt(a) != parseInt(serialToAdd)), serialToAdd])
                        validar = true
                      }
                    })
                    if (!validar) {
                      alert('el carton no es valido para este sorteo o no fue vendido aun')
                    } else {
                      input_add.current.value = ''
                    }
                  }}
              >AGREGAR</button>
          </div>
      </>)
  }
  return (
      <div className="pages-sorteo" style={{ color: 'white' }}>

          <div className="page-content">
              <div className="row-1 row-principal flex justify-center items-start mt-4">
                  { !youtubeExpand && !isMobile &&
                      <div className="column-1 column-principal z-10 ">
                          <TablaLateral />
                      </div>
                  }

                  <div className="z-[9999] w-full top-[50px] fixed lg:relative lg:top-[0px]  column-2 column-principal flex flex-col justify-center bg-black" >
                      <div className="flex justify-between bg-black items-center p-3 ">
                          <span><b> Rondas : {rondas}</b> </span>
                          <span><b>{`${dia} ${fecha}`}</b></span>
                          {
                              !youtubeExpand && !isMobile &&
                              <FontAwesomeIcon icon={faExpand} onClick={expandbeHandler} />
                          }
                          {
                              youtubeExpand && !isMobile &&
                              <FontAwesomeIcon icon={faMinimize} onClick={expandbeHandler} />
                          }
                      </div>

                      <Youtube link={link} expand={youtubeExpand} />

                      {
                          isMobile &&
                          <div className="bg-[black] flex flex-col p-1 overflow-scroll " style={{ zIndex: 10010 }}>
                              <Tablero Bolas={BolasList}/>
                              { CartonAdd()}
                          </div>
                      }
                  </div>
                  {
                      !youtubeExpand && !isMobile &&
                      <div className="column-3 column-principal mt-0  w-full flex justify-center lg:mt-[0px]"
                          style={{ zIndex: 9 }}>
                          {TablaGanadores()}
                      </div>
                  }
              </div>
              {
                  !isMobile &&

                  <div className="bg-[black] flex justify-start p-1" >
                      <Tablero Bolas={BolasList}/>
                  </div>
              }

              <div className="row-2 flex flex-wrap gap-x-[5px]">
                  { !isMobile && CartonAdd()}

                  {
                      !isMobile &&
                          <Carton
                              Bolas={BolasList}
                              CartonesSaved={CartonesLocal}
                              SaveCarton={UpdateCartonesLocal}
                              Sorteo={sorteoActual}
                              Cartones={Cartones}
                              serialToAdd={serialToAdd}
                          />
                  }
                  {
                      isMobile &&
                      <div className="mb-[20px] mt-[20px] flex  flex-wrap gap-1">
                          <Carton
                              Bolas={BolasList}
                              CartonesSaved={CartonesLocal}
                              SaveCarton={UpdateCartonesLocal}
                              Sorteo={sorteoActual}
                              Cartones={Cartones}
                              serialToAdd={serialToAdd}
                          />
                       </div>
                  }
              </div>
              {
                  isMobile &&
                  <div className="column-3 column-principal mt-0 mt-[200px] w-full flex justify-center lg:mt-[0px]"
                  style={{ zIndex: 9 }}>
                  {TablaGanadores()}
                   </div>
              }
          </div>

          <Footer noLogin={true} />
      </div>

  )
}

export default ViewSort
