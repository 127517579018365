/* eslint-disable */
import React, { useState,useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faTableList, faDollarSign, faTv, faUser } from '@fortawesome/free-solid-svg-icons'
import AuthContext from '../../context/AuthContext'

const HorizontalMenu = ({ selectedMenu, changeMenu }) => { 
  const context = useContext(AuthContext) 
  const [user, setUser] = useState(context.user)
  const [isLogued, setIsLogued] = useState(false)
  const handlerCheckLogin = async () =>{
      const loggued = await context?.CheckCacheLogin()
      if(loggued){
          setIsLogued(true)
      } else {
        setIsLogued(false)
      }
  }

  useEffect(()=>{
      handlerCheckLogin()
  },[])

  return (
    <>
    {
      isLogued&& 
      <nav className='fixed z-50 left-0 right-0 bottom-0 flex justify-around items-center text-white text-2xl bg-[purple] w-full h-[50px]'>

        <Link to='/home'>
          <button type="button">
            <span className='text-white'><FontAwesomeIcon icon={faHouse} /></span>
          </button>
        </Link>
        <Link to='/dashboard'>
            <button type="button" onClick={e => changeMenu(1)}>
            <span className='text-white'><FontAwesomeIcon icon={faTableList} /></span>
            </button>
        </Link>
        <Link to='/dashboard'>
            <button
            type='button'
            className='border-2 border-solid text-3xl border-[purple] rounded-t-full bg-[purple]'
            onClick={e => changeMenu(2)}
            >
            <span className='text-white'><FontAwesomeIcon icon={faDollarSign} /></span>
            </button>
        </Link>
        <Link to='/dashboard'>
            <button type="button" onClick={e => changeMenu(3)}>
            <span className='text-white'><FontAwesomeIcon icon={faTv} /></span>
            </button>
        </Link>
        <Link to='/dashboard'>
            <button type="button" onClick={e => changeMenu(4)}>
            <span className='text-white'><FontAwesomeIcon icon={faUser} /></span>
            </button> 
        </Link>
      </nav>
    }
    </>
  )
}


export default HorizontalMenu