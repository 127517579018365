/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Header,Footer } from '../../components';
import './home.css';  
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect'; 
import 'react-tabs/style/react-tabs.css';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga'; 
import HorizontalMenu from "../../components/HorizontalMenu/HorizontalMenu";
 import { CallToActionButton, GetAllConfigs } from "../../helpers/helpers";

function Home() {
    const location = useLocation();
    const [configs, setConfigs] = useState([]);
      
    const [vendidos,setVendidos] = useState(0);
    const [TotalRepartir, setTotalRepartir] = useState(0.00)
    const [BaseTotalRepartir, setBaseTotalRepartir] = useState(1.00)
    const [sorteoActual, setSorteoActual] = useState(); 
    const [PremiosLittle, setPremiosLittle] = useState(0.00)
    const [PremiosBig, setPremiosBig] = useState(0.00)

    const [page, setPage] = useState(0)
  
    const [publicidad, setPublicidad] = useState(0.00)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1); 
    const [precioCarton, setPrecioCarton] = useState(1.00); 

    const [porcentajePromotor, setPorcentajePromotor] = useState(0.10)
    const [porcentajeAdmin, setPorcentajeAdmin] = useState(0.05)
    const [bonusPromotor, setBonusPromotor] = useState(0.05)

    const [telegramLink, setTelegramLink] = useState('');
    const [whatsappLink, setWhatsappLink] = useState('');
    const [whatsappList, setWhatsappList] = useState([]);
    const [whatsappMode, setWhatsappMode] = useState('');



    const handlerChangePage = (newPage) => setPage(newPage)
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);
    
    const GetGeneralConfigs = async () =>{
        const allConfigs = await GetAllConfigs()
        setConfigs( allConfigs )
        console.log('configuraciones',allConfigs)
        /*
        array con los elementos tipo json
        {
            id: number;
            tipo: string;
            valor: string;
            extra: any;
        }
            se debe pedir con un find  con el tipo a buscar ejemplo 
            configs.find(item => item.tipo == 'tipo')
            const sorteoActual =  configs.find(item => item.tipo === 'sorteoActual' ).valor
        */
    }
     

    useEffect( () => {
        GetGeneralConfigs();
        const timeout = setTimeout(() => updateTotalRepartir(), 10000);
        return () => clearTimeout(timeout);
    }, []);

    
    const getUniqueConfig = (tipo = '') => configs.find(item => item.tipo === tipo )?.valor || ''
    useEffect( () => {
        // obtener datos de las congifuraciones iniciales
        setSorteoActual( getUniqueConfig('sorteo_actual') )
        setVendidos( getUniqueConfig('cartones_vendidos') )
        setPremiosLittle( parseFloat(getUniqueConfig('premio_base_small')) )
        setPremiosBig( parseFloat(getUniqueConfig('premio_base_big')) )
        setTotalRepartir( parseFloat(getUniqueConfig('premio_base_total')) )
        setBaseTotalRepartir( parseFloat(getUniqueConfig('premio_base_total')) )
        setPublicidad( parseFloat(getUniqueConfig('precio_publicidad')) )
        setFecha( getUniqueConfig('sorteo_fecha') )
        setDia( getUniqueConfig('sorteo_dia') )
        setHora( getUniqueConfig('sorteo_hora') )
        setRondas( parseInt(getUniqueConfig('sorteo_rondas')) )
        setPrecioCarton( parseFloat(getUniqueConfig('cartones_precio')) )  
        setTelegramLink( getUniqueConfig('link_telegram') )
        setWhatsappLink( getUniqueConfig('link_whatsapp') )
        setWhatsappList( getUniqueConfig('list_whatsapp') )
        setWhatsappMode( getUniqueConfig('link_whatsapp_mode') )

    }, [configs]);


    const updateTotalRepartir = async() =>{
        const pagoPromotores = 1 - porcentajePromotor - porcentajeAdmin - bonusPromotor
        const totalVendidosUsd = vendidos * precioCarton 
        let totalRecolectado = (totalVendidosUsd  * pagoPromotores ) - publicidad

       
        //ssetRondas(1) 
        const iniciaRonda = totalRecolectado <= TotalRepartir
        const unaRonda = totalRecolectado <= (BaseTotalRepartir * 2)
        const dosRonda = totalRecolectado > (BaseTotalRepartir * 2) && totalRecolectado <= (BaseTotalRepartir * 3)
        const tresRonda = totalRecolectado > (BaseTotalRepartir * 3)
        
        if(dosRonda){
            setRondas(2) 
            if(totalRecolectado > TotalRepartir){ 
                const premioS = totalRecolectado / (rondas * 8)
                setPremiosLittle(premioS ) 
                setPremiosBig((premioS) * 2 ) 
                setTotalRepartir(totalRecolectado)
            }
            console.log("dosRonda")
        }
        if(tresRonda){
            setRondas(3) 
            if(totalRecolectado > TotalRepartir){
                const premioS = totalRecolectado / (rondas * 8)
                setPremiosLittle(premioS ) 
                setPremiosBig((premioS) * 2 )  
                setTotalRepartir(totalRecolectado)
            }
            console.log("tresRonda")
        }

        if(iniciaRonda){ 
            
            console.log("iniciaRonda")
        }
        if(unaRonda){
            if(!iniciaRonda){
            //    setTotalRepartir(totalRecolectado)
                console.log("unaRonda > iniciaRonda")
            }
            console.log("unaRonda")
        }
        
        console.log("total Recolectado",totalRecolectado)
        console.log("total a repartir", TotalRepartir)
    }
    useEffect( () => {
        updateTotalRepartir() 
     }, [vendidos,PremiosBig,PremiosLittle,rondas]);
 
    const GloboPremio = ({name = "Esquinas",premio = 0,size = "40",solid}) =>{
        
        const general = 'w-full flex items-center justify-center rounded-[90px]'
        const BgGradient = `bg-[linear-gradient(0deg,#7f2e93 0%,#7f2e93 20%,#00000000 100%)]`
        const BgSolid = ` bg-[#7f2e93aa] lg:bg-[#7f2e93] `
        const Small = {
            general:'h-[40px] lg:h-[50px] text-[15px] ' + general,
            title:'text-[white] text-[12px] lg:text-[15px] mt-2 lg:-mt-0',
            premio:`text-white lg:text-[#ffcc00] text-[20px] lg:text-[30px] lg:-mt-2`,
            caja : "text-center flex flex-col lg:pt-[5px] lg:pb-[5px]  lg:-mt-2 h-[60px] lg:h-[70px] "
        }
        const Medium = {
            general:'w-[70%] lg:w-[50%] h-[70px] lg:h-[80px] text-[25px] -mt-5 lg:-mt-0 ' + general,
            title:'text-[white]  text-[20px] lg:text-[25px]',
            premio:'text-white lg:text-[#ffcc00] text-[40px]  lg:text-[60px] -mt-4',
            caja : "text-center flex flex-col pt-[5px] pb-[5px] -mt-2 lg:-mt-6 lg:gap-2"
        }
        const Large = {
            general:'h-[180px] text-[90px] ' + general,
            title:'text-[white] text-[90px]',
            premio:'text-[#ffcc00] text-[180px] ',
            caja : "text-center flex flex-col pt-[5px] pb-[5px] "
        }
        let Css_01 = general
        switch(size){
            case "small" : Css_01 = Small; break ;
            case "medium" : Css_01 = Medium; break ;
            case "large" : Css_01 = Large; break ;
            default : break ;
        }

        const bg_gradient = solid ? BgSolid : BgGradient
        return (<>
            <div className={Css_01?.general + bg_gradient}>
                <span className={Css_01?.caja} >
                    <b className={Css_01?.title}>{name}</b>
                    <b className={Css_01?.premio} style={{}}>
                        $ {premio?.toFixed(2)}
                         
                    </b>
                </span>
            </div>
        </>)
    } 
    const Header_1 = ({height,width}) =>{
        return (<>
            <div 
                className="sm:mt-[50px] lg:mt-0 flex flex-col"
                style={
                {
                    background:"url('images/bg_head_1.png')",
                    height:height,
                    padding:10,
                    backgroundSize: "cover"
                }}>
                <div 
                    className="flex flex-col lg:flex-row"
                    style={{width:"auto",gap:20,justifyContent:"space-between"}}>
                     
                    <div style={{background:"#88888800",height:"20%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",gap:20}}>
                        
                        <div className="bg-[#fff] h-[40px] text-center w-10/12 lg:w-[auto] flex items-center  justify-center p-[5px] pl-[20px] pr-[20px] lg:p-[10px] lg:pl-[50px] lg:pr-[50px] rounded-[10px]"
                            >
                            <span className="text-[14px] lg:text-[24px] text-center " style={{textAlign:"center"}}>
                                <b>Sorteo Nº: </b>
                                <b style={{color:"red"}}>{sorteoActual?.slice(0, 5)}</b>
                                <b>{sorteoActual?.slice(5, sorteoActual.length)}</b>
                            </span>
                        </div>
                    </div> 


                    <div className="flex md:flex-row lg:flex-col  p-0    justify-between lg:justify-start lg:flex-col pl-[20px] pr-[20px] gap-x-[30px] w-[auto] lg:gap-[10px]">
                    
                        
                        <div  className="flex flex-col lg:flex-row bg-[#00000000] h-[100px] lg:h-[70px] w-[100px] lg:w-[375px] text-[white]  ml-[25px] mt-[10px] lg:mt-[25px]" >
                            <div 
                                className="flex flex-col z-[10] items-center -mr-[5px]  p-[5px] bg-[#960084] w-[100px] lg:w-[50%] justify-start h-[30px] lg:h-[60px]"
                                style={ { transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077"}}>
                                <span className="text-[12px] lg:text-[20px] absolute -mt-[15px] font-bold"
                                    style={{transform:"skewX(30deg)"}}>
                                    CON TAN SOLO
                                </span>
                                <span className="text-[22px] lg:text-[32px] -mt-[5px] lg:mt-[0px] lg:text-[44px] font-bold text-[#ffcc00]" 
                                    style={{transform:"skewX(30deg)"}}>
                                    <b>$</b>
                                    <b className="ml-[10px]"  >{precioCarton}</b>
                                    <b className="text-[20px] lg:text-[32px]">
                                        ,00
                                    </b>
                                </span>
                            </div>
                            <div 
                                className="flex flex-col z-[9] items-center ml-[5px] mt-2 lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                <span className='text-[14px] lg:text-[18px] absolute -mt-[20px] font-bold' 
                                    style={{transform:"skewX(30deg)"}}>
                                    PARTICIPAS EN
                                </span>
                                <span className="text-[18px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                    <b>{rondas} RONDA{rondas>1?'S':''}</b>
                                </span>
                            </div>
                        </div>
                         
                        <div className="flex mr-[10px] p-[10px]    flex-col justify-center items-center  bg-[#ff392a] h-[100px] w-6/12  rounded-[20px] -mt-[5px] gap-y-[10px] " > 

                            <div className="flex justify-evently w-full mt-[30px] w-full" >
                                <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/envivo_icon.png" alt=""  />
                                <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                            </div>
                            <span className="text-white text-[22px] font-bold w-full">@bingo_ve</span>
                            <div  className="flex flex-row bg-[#00000000] h-[50px] lg:h-[70px] w-full lg:w-[375px] text-[white]" >
                                <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    
                                    <span className='text-[12px] lg:text-[20px] absolute -mt-[10px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        HORARIO
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] mt-[2px]" style={{transform:"skewX(30deg)"}}>
                                            <b >{hora} </b>      
                                    </span>
                                </div>
                                <div  className="flex flex-col z-[9] items-center mt-4 -ml-8 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    <span className='text-[12px] lg:text-[20px] absolute -mt-[20px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        {dia}
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                            <b >{fecha} </b>      
                                    </span>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className="bg-[#55555500] h-[100%] w-full lg:w-[45%] flex flex-col items-center"
                        >
                        
                        <div className="bg-[#9999900] h-[130px] max-[480px]:mt-[0px]  mt-[100px]  lg:h-[100px] w-[100%] flex items-end">
                            <div  className="h-[120px] lg:h-[180px] flex items-center w-[100%] justify-center rounded-[90px]"
                                style={
                                {
                                    background:"linear-gradient(0deg,#00000000 0%,#7f2e93 20%,#7f2e93 100%)"
                                }}>
                                <span className="text-center flex flex-col">
                                    <b className="text-[white] text-[24px] lg:text-[40px]">Total a repartir</b>
                                    <b className="text-[#ffcc00] text-[50px] lg:text-[80px] -mt-[20px]">$ {TotalRepartir?.toFixed(2)}</b>
                                </span>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="flex flex-col lg:flex-row h-[100%] w-[100%] gap-[20px] justify-between items-center">
                    
                    <div className="flex max-sm:mt-5 flex-col gap-[15px] w-full">
                        <div className="flex flex-row gap-[5px] w-full">
                            <GloboPremio size="small" name="Esquinas" premio={PremiosLittle } solid/>
                            <GloboPremio size="small" name="Diagonal" premio={PremiosLittle } solid/>
                            <GloboPremio size="small" name="Diagonal Invetido" premio={PremiosLittle } solid/>
                        </div>
                        <div className="flex flex-row gap-[5px] w-full  mb-[20px] lg:mb-[0px]">
                            <GloboPremio size="small" name="Cruz" premio={PremiosLittle } solid/>
                            <GloboPremio size="small" name="Linea Horizontal" premio={PremiosLittle } solid/>
                            <GloboPremio size="small" name="Linea Vertical" premio={PremiosLittle } solid/>
                        </div>
                    </div>
                    <GloboPremio size="medium" name="Carton Lleno" premio={PremiosBig } solid/>
                    {   isMobile &&
                        <div className="h-[100px] w-[200px]" 
                            style={ {  background:"url('images/SAA_Logo.png') no-repeat", backgroundPosition:"left center"  }}>
                        </div>
                    }
                </div>
            </div>
        </>)
    } 
   
    const CarrouselJs = (props) =>{
        const MobileHeight = isMobile? 400 : 550
        const MobileWidth = isMobile? 550 : 550
        return (<>
            <Carousel
                autoPlay={false}
                infiniteLoop={true}
                interval={5000}
                showArrows={true}
                dynamicHeight={false}
                stopOnHover={false}
                showThumbs={false}
                showPaid={false} 
                swipeable={false}                
                className="  h-[auto] mt-10 lg:mt-0"
            >
                <Header_1 height={'auto'} width={MobileWidth}/> 
            </Carousel>
        </>)
    }
    
 
    return (
        <div className="pages-home flex flex-col gap-[1] pb-[0px]">
            <Header loginMode={true} /> 
            <CarrouselJs   />
            <CallToActionButton url={'https://bingove.com/info'}/>  
            <Footer />
            <footer className='bottom-0  overflow-y-hidden z-[10000]'>
                <HorizontalMenu selectedMenu={page} changeMenu={handlerChangePage} />
            </footer>
        </div>
      
    );
  }
  export default Home;

 //https://api.whatsapp.com/send/?text=Hola+%2C+Quisiera+saber+mas+de+BingoVe.&type=phone_number&phone=584124431815