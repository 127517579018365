/* eslint-disable */
import { useState, useContext, useEffect } from "react";
import { bancos, defaultPromotor, metodoPago } from "../../helpers/Constants";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Toaster, toast } from 'react-hot-toast'
import { useFormik } from 'formik'
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import * as Yup from 'yup'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDollar } from "@fortawesome/free-solid-svg-icons";
import api, { apiAny } from "../../services/api";


const AutoPago = ({ setModalPagar, carrito, orderNumber,sorteoActual, changeMenu }) => {

	const context = useContext(AuthContext)
	const [data, setData] = useState({})
	const [step1, setStep1] = useState(0)
	const [localCart, setLocalCart] = useState([])
	const [dollarPrice, setDollarPrice] = useState(0)
	const [totalCartones, setTotalCartones] = useState(0);

	const [metodSelected, setMetodSelected] = useState('')
	const [tipoDocumento, setTipoDocument] = useState(['V-', 'J-', 'E-', 'RIF-', 'PASSAPORTE'])
	const [operadoras, setOperadoras] = useState(['0424', '0414', '0412', '0426', '0416'])
	const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState(tipoDocumento[0])
	const [operadoraSelected, setOperadoraSelected] = useState(operadoras[0])
	
	//para el pedido 
	const [currentPromotor, setCurrentPromotor] = useState(context?.user?.promotorId)
	const [tipoPago, setTipoPago] = useState('')
	const [datosPago, setDatosPago] = useState([])
	const [montoBs, setMontoBs] = useState('')
	const [montoUsd, setMontoUsd] = useState('')
	const [tasa, setTasa] = useState('')
	const [telefonoTitular, setTelefonoTitular] = useState('')
	const [cedulaTitular, setCedulaTitular] = useState('')
	const [bancoEmisor, setBancoEmisor] = useState('')
	const [numeroReferencia, setNumeroReferencia] = useState('')
	const [order, setOrder] = useState('')
	const [estado, setEstado] = useState('')
	const [comprobante, setComprobante] = useState('')
	const [whatsappLink, setWhatsappLink] = useState('+34641420931')
	const [promotor, setPromotor] = useState({})

	const handleVerifyPayment = () => { 
		  
		//enviarme a historial de pedidos	
		changeMenu(1)
	}

	const crearPedido = async ()=>{
		const url =  "https://api.bingove.com/v2/crearPedido" 
		const datosPagoSeleccionado = datosPago.filter(i=>i.tipo == metodSelected)

		const carritoActual = carrito.map((item)=>{
			return {
				serial: item.serial,
				numero: item.numero,
				status: item.status 
			}
		})

		const insertData =  new FormData()
		insertData.append('clientId', context.user.clientId ) 
		insertData.append('promotorId', currentPromotor ) 
		insertData.append('sorteo', sorteoActual ) 
		insertData.append('carrito', JSON.stringify(carritoActual) ) 
		insertData.append('tipoPago', metodSelected ) 
		insertData.append('datosPago', JSON.stringify(datosPagoSeleccionado[0])) 
		insertData.append('montoBs', totalCartones *  dollarPrice ) 
		insertData.append('montoUsd', totalCartones ) 
		insertData.append('tasa', dollarPrice ) //optional
		insertData.append('telefonoTitular', `${operadoraSelected}${telefonoTitular}` ) //optional
		insertData.append('cedulaTitular', `${tipoDocumentoSelected}${cedulaTitular}` ) //optional
		insertData.append('bancoEmisor', bancoEmisor ) //optional
		insertData.append('numeroReferencia', numeroReferencia ) 
		insertData.append('numeroOrden', orderNumber ) 
		//insertData.append('estado', estado ) //optional
		insertData.append('comprobante', comprobante ) //optional
		console.log('insertData', insertData )
		//if(true) return 
		await axios({
			method: "post",
			url: url,
			data: insertData,
			headers: { "Content-Type": "multipart/form-data" },
		  })
		.then((response) =>{
			//handle success 
			console.log('respuesta de la creacion',response.data)
		})
		.catch((error) =>{ 
			console.log('error al crear pedido',error )
		}); 
	}

 
	useEffect(() => { 
		const whatsappMessage = ``
		setWhatsappLink(`https://wa.me/${promotor?.telefono}?text=${whatsappMessage}`)
	}, [
		promotor,
		telefonoTitular,
		datosPago,
		cedulaTitular,
		comprobante,
		bancoEmisor,
		dollarPrice,
		numeroReferencia,
		orderNumber,
		carrito,
		sorteoActual,
		currentPromotor 
	])

	useEffect(() => {
		setData(context.user)
	}, [])

	useEffect(() => {
		//setear el precio
		setTotalCartones(dollarPrice * carrito.length)
	}, [dollarPrice])

	useEffect(() => {
		setMetodSelected(datosPago?.[0]?.tipo || '')
	}, [datosPago])
	


	const price_api = axios.create({
		baseURL: 'https://pydolarve.org/api/v1/'
	})

	const getPriceDollar = async () => {
		await price_api.get('dollar?page=bcv')
			.then((response) => {
				const monitores = response.data.monitors
				setDollarPrice(monitores.usd.price)
			})
			.catch((err) => {
				console.error("ops! ocorreu um erro" + err);
			});
	}

	const getPromotorPaymentData = async () => {
		let localPromotorId = currentPromotor
		//verificar si el promotor esta activo o actualizar
		console.log("vamos a verificar al promotor")
		console.log(`vamos a verificar al promotor el promotor ahora es: ${localPromotorId}`)
		await apiAny.get(`/v2/verificarPromotor/${currentPromotor}`)
		.then((response) => {
			if(response?.data?.status == '1'){ 
				const content = response.data.content
				setCurrentPromotor(content)
				localPromotorId = content
			} else {
				setCurrentPromotor( defaultPromotor.id )
				localPromotorId = defaultPromotor.id
			}
			console.log(`El promotor ahora es: ${localPromotorId}`)
		})
		.catch((err) => {
			console.error("error al chequear el estado de un promotor" + err);
		});

		//obtener los datos de pago del promotor
		console.log(`vamos a otener los datos de pago del promotor: ${localPromotorId}`)
		await apiAny.get( `/v2/datosPromotor/${localPromotorId}`)
		.then((response) => {
			if(response?.data?.status == '1'){ 
				const content = response.data.content
				try { 
					const promotorTemp = content[0]
					setPromotor(promotorTemp)  
					const datosPagoJson = JSON.parse(promotorTemp.datos_pago) 
					setDatosPago(datosPagoJson)  
				} catch (error) {
					console.error("Error al parsear los datos de paog" + error);
				}
			} else {
				setPromotor(defaultPromotor)
				setDatosPago(defaultPromotor.datosPago)
			}
		})
		.catch((err) => {
			console.error("Error al obtener los datos de pago" + err);
		}); 


	}

	useEffect(() => {
		
		getPriceDollar()
	}, [])

	useEffect(() => { 
		getPromotorPaymentData()
	}, []) 

	 
	const handleChange = (event) => {
		const tipoSeleccionado = event.target.value;
		//const metodo = metodoPago.find((item) => item.tipo === tipoSeleccionado);
		setMetodSelected(tipoSeleccionado || '');
		console.log(tipoSeleccionado)
	};

	const handleSteps = () => {
		//setStep1((prev)=>prev + 1) 
		if (metodSelected.tipo == 'Creditos Bingo') {
			setStep1(4)
		} else {
			setStep1((prev) => prev + 1)
		}
	}
	const handleAtras = () => {
		if (step1 > 0) {
			setStep1((prev) => prev - 1)
		}
	}

	const validationSchema = Yup.object({
		bancoEmisor: Yup.string().required("El banco emisor es requerido"),
		numeroEmisor: metodSelected.tipo === "Pago Movil"
			? Yup.string().required("Número del Emisor es obligatorio")
				.matches(/^\d+$/, "Debe contener solo números")
				.min(11, "Minimo 11 Numeros")
			: Yup.string(),
		numeroReferencia: Yup.string()
			.required("El número de referencia es requerido")
			.matches(/^\d+$/, "Debe contener solo números"),

		//capturaPago: Yup.mixed()
		//	.required("Debe subir una captura del pago")
		//	.test("fileFormat", "Solo se permiten archivos JPG o PNG", (value) => {
		//		if (!value) return false;
		//		const validFormats = ["image/jpeg", "image/png"];
		//		return validFormats.includes(value.type);
		//	}),
	});

	const formik = useFormik({
		initialValues: {
			bancoEmisor: "",
			numeroEmisor: "",
			numeroReferencia: "",
			capturaPago: null,
		},
		validationSchema,
		onSubmit: (values) => {
			HandleSumit(values)
		},
	});

	const HandleSumit = (values) => {
		
		console.log('filteredData',values)

		// me falta el api 

		crearPedido()
		setStep1(2)
		toast.success("Orden generada con éxito!");
		console.log(success)
		setModalPagar(false);
	}
	const DatosPago = () =>{ 
		const metodo =  datosPago.filter((i) => i.tipo == metodSelected) 
		console.log('datosPago', datosPago)
		console.log('metodSelected', metodSelected)
		console.log('metodo', metodo)
		if(metodo.length > 0){ 
			return Object.entries(metodo[0]?.datos).map(([key, value]) => (
				<>
					{key == 'Creditos' ?
						<div className='flex gap-2 mt-2' key={key}>
							<div className=" font-bold  flex items-center text-sm md:text-lg w-auto gap-2">
								<span className="uppercase"> Creditos:</span>
								<span className="text-lg"> {value.toFixed(2)} <FontAwesomeIcon icon={faDollar} color="green" /></span>
								<span className="text-purple-700"> Bingo Coin</span>
							</div>
						</div>
						:
						<div className='flex gap-2 mt-2' key={key}>
							<label className='text-sm md:text-lg'>{key}:</label>
							<CopyToClipboard text={value} onCopy={() => toast(`${key} Copiado ✅`)}>
								<div className=" font-bold  flex items-center text-sm md:text-lg w-auto gap-2 cursor-pointer">
									<span> {value} </span>
									<FontAwesomeIcon icon={faCopy} />
									<span className="font-[300]">copiar</span>
								</div>
							</CopyToClipboard>
						</div>}
				</>
			));
		}  
	}

	return (

		<>
			<div className='w-full h-[90%] px-3'>

				{step1 == 0 &&

					<div className="flex flex-col h-full justify-between ">
						<div className='md:px-4 px-0 flex h-[90%] flex-col w-full transform-none md:scale-[0.80] md:mt-[-30px] sm:mt-10 '>
							<div className="flex justify-between items-center  my-1 w-full ">
								<CopyToClipboard text={localCart.order} onCopy={() => toast(`Nº Pedido Copiado ✅`)}>
									<div className="flex font-bold items-center text-sm md:text-lg w-auto gap-2">
										<span>Pedido {localCart.order} </span>
										<FontAwesomeIcon icon={faCopy} />
									</div>
								</CopyToClipboard>
								<span className="font-bold">
									{
										metodSelected.tipo == 'Creditos Bingo' ?
											`1 Credito = 1$ (BCV)` :
											`1 $ = ${dollarPrice.toFixed(2)} VES`
									}

								</span>
							</div>

							<label className='pb-1'>Escoga su forma de pago</label>
							<select
								onChange={handleChange}
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
							>
								{
									datosPago.map(
										(item) => (
											<option
												defaultValue={item.tipo === "Trasferencia"}
												key={'tipo-paggo-' + item.tipo}
												value={item.tipo}
											>
												{item.tipo}
											</option>
										)
									)
								}
							</select>

							<div className='h-full w-full'>

								<div className='flex items-center bg-gray-200  mt-1 h-auto rounded-lg px-4 py-2'>

									<h3 className='flex justify-between items-center w-full text-sm'>
										<span className='font-bold'>Monto a Pagar  </span>
										{
											metodSelected.tipo == 'Creditos Bingo' &&
											<span className="text-lg "> {(carrito.length).toFixed(2)} <FontAwesomeIcon icon={faDollar} color="green" /></span>
										}
										{
											metodSelected.tipo != 'Creditos Bingo' &&
											<span className="text-lg "> {(totalCartones).toFixed(2)} VES</span>
										}
									</h3>


								</div>
								<div className="flex text-sm font-bold justify-center w-full  text-center">
									{
										metodSelected.tipo != 'Creditos Bingo' &&
										<div>
											<span className="text-blue-500">
												{`Realiza la transaccion con los datos presentados a continuacion. `}
											</span>
											<span className="text-black-500">
												{` Debe ser el monto exacto:  ${metodSelected.tipo == 'Creditos Bingo' ? (carrito.length).toFixed(2) + '$' : (totalCartones).toFixed(2) + ' VES'}`}
											</span>
										</div>

									}
								</div>
								<div className='bg-gray-200  mt-2 h-auto rounded-lg px-4 py-2'>

									<span className='text-md font-[500] uppercase text-center w-full'>
										Datos De Pago
									</span>

									<div className='flex flex-col'>
										<DatosPago />
									</div>
								</div>

								<span className="flex justify-center w-full text-red-500 text-center my-2">
									{
										metodSelected.tipo == 'Creditos Bingo' &&
										metodSelected.datos.Creditos < carrito.length &&
										"No tiene creditos suficientes para esta compra"
									}
								</span>


							</div>
						</div>
						<div className='w-full h-[10%] flex justify-center items-center gap-2 mb-2 pb-2 px-4 scale-[0.80]'>
							<button
								className='w-1/2 bg-[#bbb] uppercase font-bold rounded-md py-2 px-2'
								onClick={() => setModalPagar(false)}
							>Cancelar</button>
							<button
								className='w-1/2 bg-purple-500 disabled:bg-gray-200 uppercase font-bold text-white rounded-md py-2 px-2'
								onClick={handleSteps}
								disabled={metodSelected.tipo == 'Creditos Bingo' &&
									metodSelected.datos.Creditos < carrito.length}
							>
								{metodSelected.tipo == 'Creditos Bingo' ? 'Pagar' : 'Ya he pagado'}
							</button>
						</div>
					</div>

				}


				{step1 == 1 && (
					<div className="w-full h-full overflow-y-scroll">

						<form
							className="flex flex-col h-full justify-between"
							onSubmit={formik.handleSubmit}
						>
							<div className="px-1 py-2 flex flex-col w-full gap-2 transform-none sm:scale-[0.90]">

								<span className="font-bold text-lg w-full text-center border-b-solid border-b-2">
									Ingresa los datos de tu pago
								</span>
								<div className="flex flex-col">
									<label htmlFor="celularEmisor" className="pb-1 mt-2">
										Documento del Titular
									</label>
									<div className="flex gap-2">
										<div className="flex flex-col w-3/12">
											<select
												validate={(value)=>setTipoDocumentoSelected(value)}
												id="cedulaTipo"
												name="cedulaTipo"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.cedulaTipo}
												className={`bg-gray-50 border ${formik.touched.cedulaTipo && formik.errors.cedulaTipo
													? "border-red-500"
													: "border-gray-300"
													} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
											>
												<option value="" label="V" defaultValue={tipoDocumento[0]}></option>
												{tipoDocumento.map((tipo) => (
													<option key={tipo} value={tipo}>
														{tipo}
													</option>
												))}
											</select>
										</div>
										<div className="flex flex-col w-9/12">

											<input
												validate={(value)=>setCedulaTitular(value)}
												id="cedulaEmisor"
												name="cedulaEmisor"
												type="text"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.cedulaEmisor}
												className={`bg-gray-50 border ${formik.touched.cedulaEmisor &&
													formik.errors.cedulaEmisor
													? "border-red-500"
													: "border-gray-300"
													} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
											/>
											{formik.touched.cedulaEmisor &&
												formik.errors.cedulaEmisor && (
													<p className="text-red-500 text-sm mt-1">
														{formik.errors.cedulaEmisor}
													</p>
												)}
										</div>
									</div>
									<label htmlFor="celularEmisor" className="pb-1 mt-2">
										Numero de Telefono asociado a la cuenta
									</label>
									<div className="flex gap-2">

										<div className="flex flex-col w-3/12">

											<select
												validate={(value)=>setOperadoraSelected(value)}
												id="operadoras"
												name="operadoras"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.operadoras}
												className={`bg-gray-50 border ${formik.touched.operadoras && formik.errors.operadoras
													? "border-red-500"
													: "border-gray-300"
													} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
											>
												<option value="" label={operadoras[0]} defaultValue={operadoras[0]}></option>
												{operadoras.map((tipo) => (
													<option key={tipo} value={tipo}>
														{tipo}
													</option>
												))}
											</select>
										</div>

										<div className="flex flex-col w-9/12">
											<input
												validate={(value)=>setCedulaTitular(value)}
												id="celularEmisor"
												name="celularEmisor"
												type="text"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.celularEmisor}
												className={`bg-gray-50 border ${formik.touched.celularEmisor &&
													formik.errors.celularEmisor
													? "border-red-500"
													: "border-gray-300"
													} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
											/>
											{formik.touched.celularEmisor &&
												formik.errors.celularEmisor && (
													<p className="text-red-500 text-sm mt-1">
														{formik.errors.celularEmisor}
													</p>
												)}
										</div>
									</div>


									<label htmlFor="bancoEmisor" className="pb-1">
										Banco Emisor
									</label>
									<select
										validate={(value)=>setBancoEmisor(value)}
										id="bancoEmisor"
										name="bancoEmisor"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.bancoEmisor}
										className={`bg-gray-50 border ${formik.touched.bancoEmisor && formik.errors.bancoEmisor
											? "border-red-500"
											: "border-gray-300"
											} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
									>
										<option value="" label="-- Seleccione un Banco --"></option>
										{bancos.map((banco) => (
											<option key={banco.codigo} value={banco.nombre}>
												{banco.nombre}
											</option>
										))}
									</select>
									{formik.touched.bancoEmisor && formik.errors.bancoEmisor && (
										<p className="text-red-500 text-sm mt-1">
											{formik.errors.bancoEmisor}
										</p>
									)}
								</div>

								{metodSelected.tipo === "Pago Movil" && (
									<div className="flex flex-col">
										<label htmlFor="numeroEmisor" className="pb-1">
											Número del Emisor
										</label>
										<input
											validate={(value)=>setTelefonoTitular(value)}
											id="numeroEmisor"
											name="numeroEmisor"
											type="text"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.numeroEmisor}
											className={`bg-gray-50 border ${formik.touched.numeroEmisor &&
												formik.errors.numeroEmisor
												? "border-red-500"
												: "border-gray-300"
												} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
										/>
										{formik.touched.numeroEmisor &&
											formik.errors.numeroEmisor && (
												<p className="text-red-500 text-sm mt-1">
													{formik.errors.numeroEmisor}
												</p>
											)}
									</div>
								)}

								<div className="flex flex-col ">
									<label htmlFor="numeroReferencia" className="pb-1">
										Número de Referencia
									</label>
									<input
										validate={(value)=>setNumeroReferencia(value)}
										id="numeroReferencia"
										name="numeroReferencia"
										type="text"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.numeroReferencia}
										className={`bg-gray-50 border ${formik.touched.numeroReferencia &&
											formik.errors.numeroReferencia
											? "border-red-500"
											: "border-gray-300"
											} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
									/>
									{formik.touched.numeroReferencia &&
										formik.errors.numeroReferencia && (
											<p className="text-red-500 text-sm mt-1">
												{formik.errors.numeroReferencia}
											</p>
										)}
								</div>

								<div className="flex flex-col">
									<label htmlFor="capturaPago" className="pb-1">
										Subir Comprobante de Pago
									</label>
									<input
										id="capturaPago"
										name="capturaPago"
										type="file"
										onChange={(event) =>
											formik.setFieldValue("capturaPago", event.target.files[0])
										}
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
									/>
									{formik.touched.capturaPago && formik.errors.capturaPago && (
										<p className="text-red-500 text-sm mt-1">
											{formik.errors.capturaPago}
										</p>
									)}
								</div>
							</div>

							<div className="w-full h-[10%] flex justify-center items-center gap-2 mb-2 pb-2">
								<button
									type="button"
									className="w-28 bg-[#bbb] rounded-md py-2 px-2"
									onClick={handleAtras}
								>
									Atrás
								</button>
								<button
									type="submit"
									
									className="w-28 bg-purple-500 text-white rounded-md py-2 px-2"
								>
									Enviar
								</button>
							</div>
						</form>
					</div>
				)}
				{step1 == 2 && (
					<div className="w-full h-full overflow-y-scroll">

						<form
							className="flex flex-col h-full justify-between"
							onSubmit={formik.handleSubmit}
						>
							<div className="px-4 py-2 flex flex-col w-full gap-2 ">

								<span className="font-bold text-lg w-full text-center border-b-solid border-b-2">
									Gracias por confiar en BingoVE
								</span>
								<span>
									Su pago ha sido registrado en nuestro sistema,
									los cartones
									<b className="text-blue-500">
										{carrito.map((item) => ` #${item.numero} `)}
									</b>

									del sorteo <b>{` ${carrito[0].sorteo} `} </b>
									han sido registrados a nombre de {` ${carrito[0]?.id_cliente} `} , para completar la verificacion
									de su pago presione el boton "VERIFICAR PAGO" que lo redirigira con uno de nuestros
									promotores.

								</span>

								<CopyToClipboard text={orderNumber} onCopy={() => toast(`Nro orden Copiado ✅`)}>
									<div className=" font-bold  flex items-center text-sm md:text-lg w-auto gap-2 cursor-pointer">
										Su numero de orden es: <span> {orderNumber} </span>
										<FontAwesomeIcon icon={faCopy} />
										<span className="font-[300]">copiar</span>
									</div>
								</CopyToClipboard>
								<span>
									El nombre del promotor que validara su compra es: <b>{context.user.promotor}</b>
								</span>

								<span>
									Podra ver sus cartones en el menu "Historial de compras"
									y se agregaran automaticamente a sus cartones guardados
									una vez que se verifique el pago,
									No olvide mantener sus datos de pago actualizado uno de
									sus cartones puede ser el proximo ganador !!
								</span>
								<h3 className="w-full text-center mt-2">
									¡¡¡ MUCHA SUERTE !!!
								</h3>

							</div>

							<div className="w-full h-[10%] flex justify-center items-center gap-2 mb-2 pb-2">

								<a
									type="submit"
									href={whatsappLink}
									rel="noopener noreferrer"
                					target="_blank"
									onClick={handleVerifyPayment}
									className="no-underline w-full font-bold mx-4 uppercase bg-purple-500 text-white rounded-md py-2 px-2"
								>
									Verificar pago
								</a> 
							</div>
						</form>
					</div>
				)}


				<Toaster
					position="top-right"
					reverseOrder={false}
					toastOptions={{
						style: {
							marginTop: 50
						},
					}}
				/>
			</div>
		</>

	)
}

export default AutoPago
