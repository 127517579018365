/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useFormikContext, Field } from 'formik'

function PersonalInfo ({handlerPhone,handlerCountry,handlerNombre,isValid,dirty,onConfirmData}) {
  const { errors, touched } = useFormikContext() 

  const [isValidForm, setIsValidForm] = useState(isValid)
  const [isDirty, setIsDirty] = useState(dirty)

  const handlePhoneValue = (value) =>{
    handlerPhone(value)
  }
  const handleCountryValue = (value) =>{
    handlerCountry(value) 
  }

  const handleNombreValue = (value) =>{
    handlerNombre(value) 
  }
  const handleConfirmData = () => {
    if(onConfirmData){
      onConfirmData()
    }
  }
  
  useEffect(()=>{
    setIsValidForm(isValid)
    setIsDirty(dirty)
  },[dirty,isValid])

  return (
    <>
      <div className='flex flex-col gap-2'>
        <label className="block text-sm font-medium text-gray-700">
          Nombre y Apellido
        </label>
        <Field
          validate={handleNombreValue}
          name="name"
          type="text"
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
          placeholder="Ingrese su nombre completo"
        />
        {errors.name && touched.name && (
          <p className="text-red-500 text-sm mt-1">{errors.name}</p>
        )}

        <label className='block text-sm font-medium text-gray-700'>
          Número de Teléfono
        </label>
        <div className='flex gap-2'> 
          <Field 
            validate={handleCountryValue}
            name="countryCode"
            type="text"
            className="w-2/12 px-2 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
            placeholder="+1" 
            maxlenght='1'
          />
           
          <Field 
            validate={handlePhoneValue}
            name="phoneNumber"
            type="text"
            className="w-10/12 px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
            placeholder="Ingrese su número de teléfono" 
          />
        </div>
        {errors.countryCode && touched.countryCode && (
          <span className='text-red-500 text-sm mt-1'>{errors.countryCode}</span>
        )}
        {errors.phoneNumber && touched.phoneNumber && (
          <span className='text-red-500 text-sm mt-1'>{errors.phoneNumber}</span>
        )}
        
      </div>
      <button
        type="submit"
        className="rounded-lg mt-3 border-2 py-1 w-full font-[600] uppercase bg-[purple] text-white hover:bg-purple-950 disabled:bg-gray-500"
        disabled={!isValidForm || !isDirty}
        onClick={handleConfirmData}
      >
        Siguiente
      </button>
    </>
  )
}

export default PersonalInfo
