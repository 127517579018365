/* eslint-disable */
import React, { useEffect, useState,useContext } from "react";
import api from "../../services/api";
import '../../App.css';
import './Carton.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash,faStar} from "@fortawesome/free-solid-svg-icons"; 
import AuthContext from "../../context/AuthContext";

function CartonCart(props) {
    const {
        Bolas, 
        Cartones,
        Sorteo,
        Order,
        FooterName,
        HideName, 
        marcaAgua,
        listMode,
        Ribbon,
        hideLogo,
        send,
        renderCart

    } = props
    const context = useContext(AuthContext)
    const [data, setData] = useState({})

    useEffect(() => {
		setData(context.user)
	}, [])

    const LoadCartones = ()=>{       
        return Cartones
            .sort((a,b)=> parseInt(a.numero) - parseInt(b.numero))
            .map((e)=>{
                const numero = e?.numero 
                const nombre = e?.nombre_cliente 
                const datos = e?.datos  
                const serial = e?.serial  
                return (
                    <CartonNew 
                        key={'carton-serial-'+numero}
                        datos={ {  numero:numero,serial: serial,dueno: nombre, numeros: datos } }
            
                        />
                ) 
            }
        ) 
        
    }
     
    const CartonNew = ({ datos }) => { 
         
        let Formateado = datos?.numeros.replaceAll("[","")
        Formateado = Formateado.replaceAll("]","")
        let statusBolas = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];
        let numeros = Formateado.split(",") 
        const nro = datos?.numero
        const propietario = datos?.dueno
        const altura = hideLogo ? "195px" : "230px" 
        const last_ball = Bolas[Bolas?.length - 1] 

        const isSelected = renderCart.filter((item)=> item?.numero == nro).length > 0
        const isPending = Cartones.filter((item)=> item.status == "pendiente" && item.clientId == data.clientId ).length > 0
        
        const winner = false; 
        const isWaterMark = marcaAgua ? "carton_container marcaAgua" : "carton_container" 
        const claseContainerCss = winner ? "carton_container_winner" : isWaterMark
        return (<>
            <div className={ [listMode ? "carton_individual  list-mode" : "carton_individual  icon-mode" , isSelected && ' selectedCard ']} 
            onClick={()=> send(datos)}>
                <div className={claseContainerCss} style={{height: altura}}>
                    {
                        !hideLogo && 
                        <div>
                            <img src={"https://bingove.com/images/logo_bingove_01.png"} height={50} />
                        </div>
                    }
                    {
                        Ribbon &&
                        <div className={`carton_ribbon`}>
                            <span className={`${Ribbon?.color}`}><b> {Ribbon?.texto} </b></span>
                        </div>
                    }
                    <div className="carton_head text-white">
                        <span>CARTON {nro}</span>
                        
                    </div>
                    <div  className="carton_sorteo" >
                        <span className="carton_sorteo_span">
                            {Sorteo}
                        </span>
                    </div>
                    {
                        !listMode &&
                        <div className="carton_content_grid ">
                        {
                            numeros.map((numero,index)=>{
                                
                                if(index == 12){
                                    return (<span className="carton_number_free" key={'carton-tabla-' + index}>
                                        LIBRE
                                    </span>)
                                }
                               
                                return (
                                <span 
                                    className="carton_number_normal" 
                                    key={'carton-tabla-' + index}
                                    >
                                    {numero}
                                </span>) 

                            })
                        }
                        </div>
                    }
                    <div>
                    
                    </div>
                </div>
                {
                    FooterName &&  !HideName &&
                    <div className="carton_footer" >
                        <span>{propietario}</span>
                    </div>
                }
            </div>
        </>)
    }
    
    return ( <LoadCartones />)
  }
  
  export default CartonCart;