/* eslint-disable */
//lista de constantes

export const Paises = [    
{
    nombre:'Venezuela',
    icono:'ven',
    estados:[
        {
            nombre:'Amazonas',
            ciudades:[
                {
                    nombre:'La Esmeralda'
                },
                {
                    nombre:'San Fernando de Atabapo'
                },
                {
                    nombre:'Puerto Ayacucho'
                },
                {
                    nombre:'Isla Ratón'
                },
                {
                    nombre:'San Juan de Manapiare'
                },
                {
                    nombre:'Maroa'
                },
                {
                    nombre:'San Carlos de Río Negro'
                }
            ]
        },
        {
            nombre:'Anzoátegui',
            ciudades:[
                {
                    nombre:'Anaco'
                },
                {
                    nombre:'Aragua de Barcelona'
                },
                {
                    nombre:'Barcelona'
                },
                {
                    nombre:'Clarines'
                },
                {
                    nombre:'Onoto'
                },
                {
                    nombre:'Valle de Guanape'
                },
                {
                    nombre:'Cantaura'
                },
                {
                    nombre:'El tigrito'
                },
                {
                    nombre:'Guanta'
                },
                {
                    nombre:'Soledad'
                },
                {
                    nombre:'San Mateo'
                },
                {
                    nombre:'El Chaparro'
                },
                {
                    nombre:'Pariaguán'
                },
                {
                    nombre:'Mapire'
                },
                {
                    nombre:'Puerto Píritu'
                },
                {
                    nombre:'Píritu'
                },
                {
                    nombre:'Boca de Uchire'
                },
                {
                    nombre:'Santa Ana'
                },
                {
                    nombre:'El Tigre'
                },
                {
                    nombre:'Puerto La Cruz'
                },
                {
                    nombre:'Lechería'
                } 
            ]
        },
        {
            nombre:'Apure',
            ciudades:[
                {
                    nombre:'Achaguas'
                },
                {
                    nombre:'Biruaca'
                },
                {
                    nombre:'San Juan de Payara'
                },
                {
                    nombre:'Bruzual'
                },
                {
                    nombre:'Guasdualito'
                },
                {
                    nombre:'Elorza'
                },
                {
                    nombre:'San Fernando de Apure'
                } 
            ]
        },
        {
            nombre:'Aragua',
            ciudades:[
                {
                    nombre:'Santa Rita'
                },
                {
                    nombre:'San Mateo'
                },
                {
                    nombre:'Camatagua'
                },
                {
                    nombre:'Maracay'
                },
                {
                    nombre:'El Limón'
                },
                {
                    nombre:'Santa Cruz de Aragua'
                },
                {
                    nombre:'Palo Negro'
                },
                {
                    nombre:'Turmero'
                },
                {
                    nombre:'Las Tejerías'
                },
                {
                    nombre:'Ocumare de la Costa'
                },
                {
                    nombre:'El Consejo'
                },
                {
                    nombre:'La Victoria'
                },
                {
                    nombre:'San Casimiro'
                },
                {
                    nombre:'San Sebastián de los Reyes'
                },
                {
                    nombre:'Cagua'
                },
                {
                    nombre:'Colonia Tovar'
                },
                {
                    nombre:'Barbacoas'
                },
                {
                    nombre:'Villa de Cura'
                }
            ]
        },
        {
            nombre:'Barinas',
            ciudades:[
                {
                    nombre:'Sabaneta'
                },
                {
                    nombre:'El Cantón'
                },
                {
                    nombre:'Socopó'
                },
                {
                    nombre:'Arismendi'
                },
                {
                    nombre:'Barinas'
                },
                {
                    nombre:'Barinitas'
                },
                {
                    nombre:'Barrancas'
                },
                {
                    nombre:'Santa Bárbara'
                },
                {
                    nombre:'Obispos'
                },
                {
                    nombre:'Ciudad Bolivia'
                },
                {
                    nombre:'Libertad'
                },
                {
                    nombre:'Ciudad de Nutrias'
                } 
            ]
        },
        {
            nombre:'Bolívar',
            ciudades:[
                {
                    nombre:'Ciudad Piar'
                },
                {
                    nombre:'Ciudad Bolívar'
                },
                {
                    nombre:'Ciudad Guayana'
                },
                {
                    nombre:'Caicara del Orinoco'
                },
                {
                    nombre:'El Palmar'
                },
                {
                    nombre:'El Callao'
                },
                {
                    nombre:'Santa Elena de Uairén'
                },
                {
                    nombre:'Upata'
                },
                {
                    nombre:'Guasipati'
                },
                {
                    nombre:'El Dorado'
                },
                {
                    nombre:'Maripa'
                }
            ]
        },
        {
            nombre:'Carabobo',
            ciudades:[
                {
                    nombre:'Bejuma'
                },
                {
                    nombre:'Güigüe'
                },
                {
                    nombre:'Mariara'
                },
                {
                    nombre:'Guacara'
                },
                {
                    nombre:'Morón'
                },
                {
                    nombre:'Tocuyito'
                },
                {
                    nombre:'Los Guayos'
                },
                {
                    nombre:'Miranda'
                },
                {
                    nombre:'Montalbán'
                },
                {
                    nombre:'Naguanagua'
                },
                {
                    nombre:'Puerto Cabello'
                },
                {
                    nombre:'San Diego'
                },
                {
                    nombre:'San Joaquín'
                },
                {
                    nombre:'Valencia'
                } 
            ]
        },
        {
            nombre:'Cojedes',
            ciudades:[
                {
                    nombre:'Cojedes'
                },
                {
                    nombre:'San Carlos'
                },
                {
                    nombre:'El Baúl'
                },
                {
                    nombre:'Macapo'
                },
                {
                    nombre:'El Pao'
                },
                {
                    nombre:'Libertad'
                },
                {
                    nombre:'Las Vegas'
                },
                {
                    nombre:'Tinaco'
                },
                {
                    nombre:'Tinaquillo'
                }
            ]
        },
        {
            nombre:'Delta Amacuro',
            ciudades:[
                {
                    nombre:'Curiapo'
                },
                {
                    nombre:'Sierra Imataca'
                },
                {
                    nombre:'Pedernales'
                },
                {
                    nombre:'Tucupita'
                }
            ]
        },
        {
            nombre:'Distrito Capital',
            ciudades:[
                {
                    nombre:'Caracas'
                } 
            ]
        },
        {
            nombre:'Falcón',
            ciudades:[
                {
                    nombre:'San Juan de los Cayos'
                },
                {
                    nombre:'San Luis'
                },
                {
                    nombre:'Capatárida'
                },
                {
                    nombre:'Punto Fijo'
                },
                {
                    nombre:'La Vela de Coro'
                },
                {
                    nombre:'Dabajuro'
                },
                {
                    nombre:'Pedregal'
                },
                {
                    nombre:'Pueblo Nuevo'
                },
                {
                    nombre:'Churuguara'
                },
                {
                    nombre:'Chichiriviche'
                },
                {
                    nombre:'Jacura'
                },
                {
                    nombre:'Santa Cruz de Los Taques'
                },
                {
                    nombre:'Yaracal'
                },
                {
                    nombre:'Mene de Mauroa'
                },
                {
                    nombre:'Santa Ana de Coro'
                },
                {
                    nombre:'Palmasola'
                },
                {
                    nombre:'Cabure'
                },
                {
                    nombre:'Píritu'
                },
                {
                    nombre:'Mirimire'
                },
                {
                    nombre:'La Cruz de Taratara'
                },
                {
                    nombre:'Tucacas'
                },
                {
                    nombre:'Tocópero'
                },
                {
                    nombre:'Santa Cruz de Bucaral'
                },
                {
                    nombre:'Urumaco'
                },
                {
                    nombre:'Puerto Cumarebo'
                }
            ]
        },
        {
            nombre:'Guárico',
            ciudades:[
                {
                    nombre:'Camaguán'
                },
                {
                    nombre:'Chaguaramas'
                },
                {
                    nombre:'El Socorro'
                },
                {
                    nombre:'Calabozo'
                },
                {
                    nombre:'Tucupido'
                },
                {
                    nombre:'Altagracia de Orituco'
                },
                {
                    nombre:'San Juan de los Morros'
                },
                {
                    nombre:'Las Mercedes'
                },
                {
                    nombre:'El Sombrero'
                },
                {
                    nombre:'Valle de La Pascua'
                },
                {
                    nombre:'Ortiz'
                },
                {
                    nombre:'Guayabal'
                },
                {
                    nombre:'San José de Guaribe'
                },
                {
                    nombre:'Santa María de Ipire'
                },
                {
                    nombre:'Zaraza'
                }
            ]
        },
        {
            nombre:'Lara',
            ciudades:[
                {
                    nombre:'Sanare'
                },
                {
                    nombre:'Duaca'
                },
                {
                    nombre:'Barquisimeto'
                },
                {
                    nombre:'Quibor'
                },
                {
                    nombre:'El Tocuyo'
                },
                {
                    nombre:'Cabudare'
                },
                {
                    nombre:'Sarare'
                },
                {
                    nombre:'Carora'
                },
                {
                    nombre:'Siquisique'
                }
            ]
        },
        {
            nombre:'La Guaira',
            ciudades:[
                {
                    nombre:'La Guaira'
                }
            ]
        },
        {
            nombre:'Mérida',
            ciudades:[
                {
                    nombre:'El Vigía'
                },
                {
                    nombre:'La Azulita'
                },
                {
                    nombre:'Santa Cruz de Mora'
                },
                {
                    nombre:'Aricagua'
                },
                {
                    nombre:'Canaguá'
                },
                {
                    nombre:'Ejido'
                },
                {
                    nombre:'Tucaní'
                },
                {
                    nombre:'Santo Domingo'
                },
                {
                    nombre:'Guaraque'
                },
                {
                    nombre:'Arapuey'
                },
                {
                    nombre:'Torondoy'
                },
                {
                    nombre:'Mérida'
                },
                {
                    nombre:'Timotes'
                },
                {
                    nombre:'Santa Elena de Arenales'
                },
                {
                    nombre:'Santa María de Caparo'
                },
                {
                    nombre:'Pueblo Llano'
                },
                {
                    nombre:'Mucuchíes'
                },
                {
                    nombre:'Bailadores'
                },
                {
                    nombre:'Tabay'
                },
                {
                    nombre:'Lagunillas'
                },
                {
                    nombre:'Tovar'
                },
                {
                    nombre:'Nueva Bolivia'
                },
                {
                    nombre:'Zea'
                } 
            ]
        },
        {
            nombre:'Miranda',
            ciudades:[
                {
                    nombre:'Caucagua'
                },
                {
                    nombre:'San José de Barlovento'
                },
                {
                    nombre:'Baruta'
                },
                {
                    nombre:'San Francisco de Yare'
                },
                {
                    nombre:'Higuerote'
                },
                {
                    nombre:'Mamporal'
                },
                {
                    nombre:'Carrizal'
                },
                {
                    nombre:'Chacao'
                },
                {
                    nombre:'Charallave'
                },
                {
                    nombre:'El Hatillo'
                },
                {
                    nombre:'Los Teques'
                },
                {
                    nombre:'Cúpira'
                },
                {
                    nombre:'Santa Teresa del Tuy'
                },
                {
                    nombre:'Ocumare del Tuy'
                },
                {
                    nombre:'San Antonio de los Altos'
                },
                {
                    nombre:'Río Chico'
                },
                {
                    nombre:'Santa Lucía'
                },
                {
                    nombre:'Guarenas'
                },
                {
                    nombre:'Petare'
                },
                {
                    nombre:'Cúa'
                },
                {
                    nombre:'Guatire'
                } 
            ]
        },
        {
            nombre:'Monagas',
            ciudades:[
                {
                    nombre:'San Antonio de Capayacuar'
                },
                {
                    nombre:'Aguasay'
                },
                {
                    nombre:'Caripito'
                },
                {
                    nombre:'Caripe'
                },
                {
                    nombre:'Caicara de Maturín'
                },
                {
                    nombre:'Temblador'
                },
                {
                    nombre:'Maturín'
                },
                {
                    nombre:'Aragua de Maturín'
                },
                {
                    nombre:'Quiriquire'
                },
                {
                    nombre:'Santa Bárbara'
                },
                {
                    nombre:'Barrancas del Orinoco'
                },
                {
                    nombre:'Uracoa'
                },
                {
                    nombre:'Punta de Mata'
                } 
            ]
        },
        {
            nombre:'Nueva Esparta',
            ciudades:[
                {
                    nombre:'La Plaza de Paraguachí'
                },
                {
                    nombre:'San Juan Bautista'
                },
                {
                    nombre:'La Asunción'
                },
                {
                    nombre:'El Valle'
                },
                {
                    nombre:'Santa Ana'
                },
                {
                    nombre:'Boca de Río'
                },
                {
                    nombre:'Pampatar'
                },
                {
                    nombre:'Juan Griego'
                },
                {
                    nombre:'Porlamar'
                },
                {
                    nombre:'Punta de Piedras'
                },
                {
                    nombre:'San Pedro de Coche'
                } 
            ]
        },
        {
            nombre:'Portuguesa',
            ciudades:[
                {
                    nombre:'Agua Blanca'
                },
                {
                    nombre:'Araure'
                },
                {
                    nombre:'Píritu'
                },
                {
                    nombre:'Guanare'
                },
                {
                    nombre:'Guanarito'
                },
                {
                    nombre:'Chabasquén'
                },
                {
                    nombre:'Ospino'
                },
                {
                    nombre:'Acarigua'
                },
                {
                    nombre:'Papelón'
                },
                {
                    nombre:'Boconoíto'
                },
                {
                    nombre:'San Rafael de Onoto'
                },
                {
                    nombre:'El Playón'
                },
                {
                    nombre:'Biscucuy'
                },
                {
                    nombre:'Villa Bruzual'
                } 
            ]
        },
        {
            nombre:'Sucre',
            ciudades:[
                {
                    nombre:'Casanay'
                },
                {
                    nombre:'San José de Aerocuar'
                },
                {
                    nombre:'Río Caribe'
                },
                {
                    nombre:'El Pilar'
                },
                {
                    nombre:'Carúpano'
                },
                {
                    nombre:'Marigüitar'
                },
                {
                    nombre:'Yaguaraparo'
                },
                {
                    nombre:'Araya'
                },
                {
                    nombre:'Tunapuy'
                },
                {
                    nombre:'Irapa'
                },
                {
                    nombre:'San Antonio del Golfo'
                },
                {
                    nombre:'Cumanacoa'
                },
                {
                    nombre:'Cariaco'
                },
                {
                    nombre:'Cumaná'
                },
                {
                    nombre:'Güiria'
                } 
            ]
        },
        {
            nombre:'Táchira',
            ciudades:[
                {
                    nombre:'Cordero'
                },
                {
                    nombre:'Las Mesas'
                },
                {
                    nombre:'Colón'
                },
                {
                    nombre:'San Antonio del Táchira'
                },
                {
                    nombre:'Táriba'
                },
                {
                    nombre:'Santa Ana de Táchira'
                },
                {
                    nombre:'San Rafael del Piñal'
                },
                {
                    nombre:'San José de Bolívar'
                },
                {
                    nombre:'La Fría'
                },
                {
                    nombre:'Palmira'
                },
                {
                    nombre:'Capacho Nuevo'
                },
                {
                    nombre:'La Grita'
                },
                {
                    nombre:'El Cobre'
                },
                {
                    nombre:'Rubio'
                },
                {
                    nombre:'Capacho Viejo'
                },
                {
                    nombre:'Abejales'
                },
                {
                    nombre:'Lobatera'
                },
                {
                    nombre:'Michelena'
                },
                {
                    nombre:'Coloncito'
                },
                {
                    nombre:'Ureña'
                },
                {
                    nombre:'Delicias'
                },
                {
                    nombre:'La Tendida'
                },
                {
                    nombre:'San Cristóbal'
                },
                {
                    nombre:'Umuquena'
                },
                {
                    nombre:'San Simón'
                },
                {
                    nombre:'Queniquea'
                },
                {
                    nombre:'San Josecito'
                },
                {
                    nombre:'Pregonero'
                }
            ]
        },
        {
            nombre:'Trujillo',
            ciudades:[
                {
                    nombre:'Santa Isabel'
                },
                {
                    nombre:'Boconó'
                },
                {
                    nombre:'Sabana Grande'
                },
                {
                    nombre:'Chejendé'
                },
                {
                    nombre:'Carache'
                },
                {
                    nombre:'Carvajal'
                },
                {
                    nombre:'Escuque'
                },
                {
                    nombre:'Campo Elías'
                },
                {
                    nombre:'Santa Apolonia'
                },
                {
                    nombre:'El Paradero'
                },
                {
                    nombre:'El Dividive'
                },
                {
                    nombre:'Monte Carmelo'
                },
                {
                    nombre:'Motatán'
                },
                {
                    nombre:'Pampán'
                },
                {
                    nombre:'Pampanito'
                },
                {
                    nombre:'Betijoque'
                },
                {
                    nombre:'Sabana de Mendoza'
                },
                {
                    nombre:'Trujillo'
                },
                {
                    nombre:'La Quebrada'
                },
                {
                    nombre:'Valera'
                }
            ]
        },
        {
            nombre:'Yaracuy',
            ciudades:[
                {
                    nombre:'San Pablo'
                },
                {
                    nombre:'Aroa'
                },
                {
                    nombre:'Chivacoa'
                },
                {
                    nombre:'Cocorote'
                },
                {
                    nombre:'Independencia'
                },
                {
                    nombre:'Sabana de Parra'
                },
                {
                    nombre:'Boraure'
                },
                {
                    nombre:'Yumare'
                },
                {
                    nombre:'Nirgua'
                },
                {
                    nombre:'Yaritagua'
                },
                {
                    nombre:'San Felipe'
                },
                {
                    nombre:'Guama'
                },
                {
                    nombre:'Urachiche'
                },
                {
                    nombre:'Farriar'
                } 
            ]
        },
        {
            nombre:'Zulia',
            ciudades:[
                {
                    nombre:'El Toro'
                },
                {
                    nombre:'San Timoteo'
                },
                {
                    nombre:'Cabimas'
                },
                {
                    nombre:'Encontrados'
                },
                {
                    nombre:'San Carlos del Zulia'
                },
                {
                    nombre:'Pueblo Nuevo-El Chivo'
                },
                {
                    nombre:'Sinamaica'
                },
                {
                    nombre:'La Concepción'
                },
                {
                    nombre:'Casigua El Cubo'
                },
                {
                    nombre:'Concepción'
                },
                {
                    nombre:'Ciudad Ojeda'
                },
                {
                    nombre:'Machiques'
                },
                {
                    nombre:'San Rafael del Moján'
                },
                {
                    nombre:'Maracaibo'
                },
                {
                    nombre:'Los Puertos de Altagracia'
                },
                {
                    nombre:'La Villa del Rosario'
                },
                {
                    nombre:'San Francisco'
                },
                {
                    nombre:'Santa Rita'
                },
                {
                    nombre:'Tía Juana'
                },
                {
                    nombre:'Bobures'
                },
                {
                    nombre:'Bachaquero'
                } 
            ]
        },
        {
            nombre:'Nivel Nacional',
            ciudades:[
                {nombre:'Nivel Nacional'}
            ]
        }
    ],
    activo:true
},
{
    nombre:'España',
    icono:'spain',
    estados:[{
        nombre:'Nivel nacional',
        ciudades:[
            {
                nombre:'Nivel Nacional'
            }
        ]
    }],
    activo:true
},
{
    nombre:'Brasil',
    icono:'brasil',
    estados:[{
        nombre:'Nivel nacional',
        ciudades:[
            {
                nombre:'Nivel Nacional'
            }
        ]
    }],
    activo:true
},
{
    nombre:'Colombia',
    icono:'col',
    estados:[{
        nombre:'Nivel nacional',
        ciudades:[
            {
                nombre:'Nivel Nacional'
            }
        ]
    }],
    activo:true
}, 
{
    nombre:'Otro Pais',
    icono:'global',
    estados:[{
        nombre:'Nivel internacional',
        ciudades:[
            {nombre:'Nivel Nacional'}
        ]
    }],
    activo:true
}

]

export const bancos = [

	{
		"codigo": "0105",
		"nombre": "Mercantil Banco Universal"   
	},
	{
		"codigo": "0102", 
		"nombre": "Banco De Venezuela"   
	},
	{
		"codigo": "0104", 
		"nombre": "Venezolano De Credito"   
	},
	{
		"codigo": "0108", 
		"nombre": "Banco Provincial"   
	},
	{
	    "codigo":"0114",
		"nombre":"Banco Del Bancaribe"   
	},
	{
		"codigo": "0115", 
		"nombre": "Banco Exterior"   
	},
	{
		"codigo": "0128",
		"nombre": "Banco Caroni"   
	},
	{
		"codigo":"0134",
		"nombre": "Banesco Banco Universal"
	},
	{
		"codigo":"0137",
		"nombre": " Banco Sofitasa"
	},
	{
		"codigo":"0138",
		"nombre": "Banco Plaza"
	},
	{
		"codigo":"0151",
		"nombre": "Banco Fondo Comun"
	},
	{
		"codigo":"0156",
		"nombre": "100% Banco"
	},
	{
		"codigo":"0157",
		"nombre": "Banco Del Sur"
	},
	{
		"codigo":"0163",
		"nombre": "Banco Del Tesoro"
	},
	{
		"codigo":"0166",
		"nombre": "Banco Agricola De Venezuela"
	},
	{
		"codigo":"0168",
		"nombre": "Bancrecer"
	},
	{
		"codigo":"0169",
		"nombre": "Mi Banco"
	},
	{
		"codigo":"0171",
		"nombre": "Banco Activo"
	},
	{
		"codigo":"0172",
		"nombre": "Bancamiga"
	},
	{
		"codigo":"0174",
		"nombre": "Banplus"
	},
	{
		"codigo":"0175",
		"nombre": "Banco Bicentenario"
	},
	{
		"codigo":"0177",
		"nombre": "Banco De Las Fuerza Armada Bolivariana"
	},
	{
		"codigo":"0191",
		"nombre": "Banco Nacional De Credito"
	},

]

export const metodoPago = [
    {
      "tipo": "Trasferencia",
      "datos": {
        "Banco":"Banco de Venezuela",
        "Tipo Cuenta":"ahorros",
        "Titular": "Carlos Morales",
        "NroCuenta": "010206181000285852",
        "Cedula": "27928349"
      }
    },
    {
      "tipo": "Pago Movil",
      "datos": {
        "Banco":"0102 Banco de Venezuela",
        "Titular": "Carlos Morales",
        "Telefono": "04248707122",
        "Cedula": "27928349"
      }
    },
    {
        "tipo": "Creditos Bingo",
        "datos": {
          "Creditos": 0.00  
        }
    }
  ]

export const defaultPromotor = {
    id: 140,
    nombre: "Desarrollo",
    metodoPago: metodoPago,
    telefono:'+34641420931'

}

