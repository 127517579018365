/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { FieldArray, useFormikContext } from 'formik'

const PhoneValidation = ({ nextStep, destino, code , sendCode, isValid,dirty,onConfirmData,onCancel}) => {
  const refs = Array.from({ length: 6 }, () => useRef(null)) // Referencias para los inputs
  const { values, errors, touched, setFieldValue } = useFormikContext() // Acceso al contexto de Formik
  const [isValidCode, setIsValidCode ] = useState(null)
  const [ conteo, setConteo ] = useState(120)
  const [isValidForm, setIsValidForm] = useState(isValid)
  const [isDirty, setIsDirty] = useState(dirty)

  const handleResendCode = () => {
    values.phoneCode =  Array(6).fill('')
    setConteo(60)
    if(sendCode){ 
      sendCode()
    }
  }
  const handleConfirmData = () => {
    if(onConfirmData){
      onConfirmData()
    }
  }
  const handleCancel= () => {
    if(onCancel){
      onCancel()
    }
  }
  const handleChange = (e, index) => {
    const value = e.target.value

    if (/^\d{0,1}$/.test(value)) {
      const updatedCode = [...values.phoneCode]
      updatedCode[index] = value
      setFieldValue('phoneCode', updatedCode)

      if (value && index < refs.length - 1) {
        refs[index + 1].current.focus() // Mover el foco al siguiente input
      }
    }

    
  }

  useEffect(() => {
    refs[0].current?.focus() // Foco inicial en el primer input
  }, [])
  useEffect(()=>{
    const timer = setInterval(()=>{
      setConteo((prev)=>{
        if(prev < 2){
          return 0
        } else {
          return prev - 1
        }

      } ) 
    },1000)
    return ()=>clearInterval(timer)
  },[])

  useEffect(() => {
    if(isValidCode === true ){

      nextStep()
    }
  }, [isValidCode])
  useEffect(()=>{
    setIsValidForm(isValid)
    setIsDirty(dirty)
  },[dirty,isValid])
  

  const handleValidation = (e) => {
    e?.preventDefault()
    if (!errors.phoneCode && values.phoneCode.join('').length === 6) {
      //validacion del codigo
      //alert(code)
      if(code == values.phoneCode.join('')){ 
        setIsValidCode( true ) 
         // Avanzar a la siguiente etapa
      } else {
        setIsValidCode( false ) 
      }

    } 
  }

  return (
    <div>
      <label className="block text-xs text-center font-medium text-gray-700">
      Se ha enviado un codigo de verificacion al siguiente numero {destino}  Ingrese el código de 6 dígitos recibido
      <button
        type="button"
        onClick={handleResendCode}
        disabled={conteo > 0}
        className="bg-transparent disabled:text-gray-500 text-purple-500 text-sm py-1 px-4 rounded hover:bg-purple-700 focus:outline-none"
      >
        {
          conteo > 0 &&
          <span> 
            ({conteo}s)
          </span> 
        }
        <span> 
          Reenviar codigo
        </span>


      </button>
      </label>
      <div className="flex gap-1 mt-2">
        <FieldArray
          name="phoneCode"
          render={() =>
            values.phoneCode.map((value, index) => (
              <input
                key={index}
                type="number"
                ref={refs[index]}
                style={{
                  border: isValidCode == false ? 'solid 1px red': isValidCode == true ? 'solid 1px green':'solid purple 2px'
                }}
                className="w-1/6 text-center text-[purple] text-3xl font-bold py-1 border rounded-md focus:outline-none focus:ring focus:ring-[purple] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                value={value}
                maxLength={1}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => {
                  if(index > 4){
                    handleValidation() 
                  }
                  if (e.key === 'Backspace' && !value && index > 0) {
                    refs[index - 1].current.focus() // Mover foco al anterior si borra
                  }
                }}
              />
            ))
          }
        />
      </div>
      {errors.phoneCode && touched.phoneCode && (
        <div className="text-red-500 text-sm mt-2">
          {errors.phoneCode.join(', ')}
        </div>
      )}
      { isValidCode == false &&  (
        <div className="text-red-500 text-sm mt-2">
          El codigo proporcionado no es valido
        </div>
      )}
       { isValidCode == true &&  (
        <div className="text-red-500 text-sm mt-2">
          Codigo Verificado con exito
        </div>
      )}
       
      <div className='flex justify-between my-3'> 
        
        <button
          type="button"
          className="rounded-lg border-2 w-full mr-4 p-1 font-[600] uppercase bg-[purple] text-white hover:bg-purple-950 disabled:bg-gray-500"
          onClick={handleCancel}
        >
          Anterior
        </button>
        <button
          type="submit"
          className="rounded-lg border-2 py-1 w-full font-[600] uppercase ml-4 bg-[purple] text-white hover:bg-purple-950 disabled:bg-gray-500"
          disabled={!isValidForm || !isDirty}
          onClick={handleValidation}
        >
          Siguiente
        </button>
      </div> 
    </div>
  )
}

export default PhoneValidation
